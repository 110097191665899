import { store } from '../store';
import ApiClient from './api-client';
import UserAdminHelper from './user-admin-helper';
import WorkplanHelper from './workplan-helper';

export default class ApiHelper {

    static setUserLoginRoles = (setUserLoginRoles) => {
        const state = store.getState();
        
        if ((state.userAdmin.loginRoles || []).length === 0) {
            ApiClient.post("UserAdmin/GetUserLoginRoles")
                .then(response => {
                    
                    setUserLoginRoles(response.data);
                }).catch(error => {
                    console.error(error);;
                });
        }
    }

    static setCountriesWithDetails = (setCountriesWithDetails, setAllCustomer = null) => {
        const state = store.getState();
        if ((state.user.countries || []).length === 0) {
            ApiClient.post("User/GetCountriesWithDetails")
                .then(response => {
                    setCountriesWithDetails(response.data);
                    if (setAllCustomer) {
                        setAllCustomer(response.data?.map(c => c.customers)?.reduce((a, b) => a.concat(b)));
                    }
                }).catch(error => {
                    console.error(error);;
                });
        }
    }

    static setAllUsers = (setAllUsers) => {
        const state = store.getState();
        if ((state.user.users || []).length === 0) {
            ApiClient.post("User/GetAllUsers")
                .then(response => {
                    setAllUsers(response.data);
                }).catch(error => {
                    console.error(error);;
                });
        }
    }

 static  refreshAllUserDataInBackgroud = (setUserLoginRoles,setRscCenters,login,setWebToken,setUserRefreshed )=> {

    const state = store.getState();
    if (state.user.userRefreshed)
       return
    ApiClient.post("UserAdmin/GetUserLoginRoles")
    .then(loginRoles => {
        setUserLoginRoles(loginRoles.data);
        ApiClient.post("UserAdmin/GetRscCenters")
            .then(response => {
                const rscCenters = response.data;
                if (rscCenters) {
                    rscCenters.map(r => {
                        r.value = r.id;
                        r.text = r.name;
                    });
                }
                setRscCenters(rscCenters);
                this.fetchUSerDetail(login,setWebToken,loginRoles.data , rscCenters,setUserRefreshed)
            }).catch(error => {
                console.error(error);
                
            });
    }).catch(error => {
        console.error(error);
        
    });
}

static  fetchUSerDetail = (login,setWebToken , loginRoles , rscCenters,setUserRefreshed ) => {
    
        
    const onSuccess = (response) => {
    
           setWebToken(response.data.user?.webToken);
            const loginUser = UserAdminHelper.mapLoginUser(response.data, loginRoles, rscCenters);
            loginUser.rememberMe = true;

            login(loginUser);
            setUserRefreshed(true)
            
           // setTimeout(() => this.props.history.push("/"), 1000);
          
    };
    const onError = (error) => {
       console.error(error);
       setUserRefreshed(false)
      
    };
   ApiClient.post("User/GetUserConfig",{includeEveryThing : true}).then(onSuccess).catch(onError);

   }

    static setRscCenters = (setRscCenters) => {
        const state = store.getState();
        if ((state.user.rscCenters || []).length === 0) {
            ApiClient.post("UserAdmin/GetRscCenters")
                .then(response => {
                    const rscCenters = response.data;
                    if (rscCenters) {
                        rscCenters.map(r => {
                            r.value = r.id;
                            r.text = r.name;
                        });
                    }
                    setRscCenters(rscCenters);
                }).catch(error => {
                    console.error(error);;
                });
        }
    }

    
    static getRscCenter = (role) => {
        const state = store.getState();
        const rscPerson = state.user.rscPerson;
        const rscCenters = state.user.rscCenters;

        return (rscPerson?.personType?.toString() === role && rscPerson?.rscCenterId
            ? "(" + rscCenters?.find(r => r.value === rscPerson?.rscCenterId).text + ")"
            : "");
    }

    static setAspPmUnAssignedActivities = (setAspPmUnAssignedActivities) => {
        {
            const state = store.getState();
            const filter = { ...state.activity.filter };
            const filterTypes = [...state.activity.aspPmFilterTypes];
            if (filterTypes?.includes("1")) {
                filter.assigned = true;
            } if (filterTypes?.includes("2")) {
                filter.unAssigned = true;
            } if (filterTypes?.includes("3")) {
                filter.closed = true;
            }

            filter.userId = parseInt(state.user.userData.userId);
            filter.id = parseInt(filter.id ?? "0");
            filter.sessionId = parseInt(filter.sessionId ?? "0");
            
            if (!filter.teamLeaderIds) {
                filter.teamLeaderIds = [];
            }
            ApiClient.post("Activity/GetFilteredActivities", filter)
                .then(response => {
                    if (response.data) {
                        
                        const data = [...response.data?.data];
                        
                        WorkplanHelper.mapActivities(data);
                        setAspPmUnAssignedActivities(data);
                    }

                })
                .catch(error => {
                    console.error(error);;

                });
        }
    }

    static setAllRscUsers = async (setAllRscUsers) => {
        const state = store.getState();       
        if ((state.rsc.allRscUsers || []).length === 0) {
            var response = await ApiClient.post("User/GetAllRscUsers");
            if (response.data) {
                setAllRscUsers(response.data);
            } else {
                
            }
        }
    }

    static setAllRscInspectors = async (setAllRscInspectors) => {
            var response = await ApiClient.post("User/GetAllRscInspectors");
            if (response.data) {
                
                setAllRscInspectors(response.data);
            } else {
                
            }
    }
}