import { AuthenticationContext , adalGetToken } from 'react-adal';

const AzureADURLS = {
    local3000 : "http://localhost:3000",
    local44375 : "https://localhost:44375",
    staging : "https://staging.remote.acceptance.customerprojects.ericsson.net",
    production : "https://remote-acceptance-customerprojects.ericsson.net",
    uat :  "https://uat.remote.acceptance.customerprojects.ericsson.net"
};
export const adalConfig = {
    tenant: '92e84ceb-fbfd-47ab-be52-080c6b87953f',
    clientId: '3dba8cd5-0018-49dc-bb9f-a5cf530bf7e6',
    redirectUri: AzureADURLS.uat,
    endpoints: {
        api: '3dba8cd5-0018-49dc-bb9f-a5cf530bf7e6',
    },
    cacheLocation: 'sessionStorage'
};


export const authContext = new AuthenticationContext(adalConfig);

export const getAuthToken = () => authContext.getCachedToken(adalConfig.clientId);
export const isLoggedIn = () => {
    var error = authContext.getLoginError()
    let token = authContext.getCachedToken(adalConfig.clientId)
    return  error == null
};

export const getTokenAndRenew  = ()  => {
 const resource = {resourceGuiId : adalConfig.clientId}
 return adalGetToken(authContext,resource)
 
};


