import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../../layout";
import EuiRow from "../../../eui-components/row";
import EuiTile from "../../../eui-components/tile";
import Table from "../../../components/table";
import PageHelper from "../../../utils/page-helper";
import CheckIcon from '@material-ui/icons/Check';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ApiClient from "../../../utils/api-client";
import { cloneDeep } from "lodash";
import DialogModal from "../../../components/dialog";
import DateUtils from "../../../utils/date-utils";
import Rater from 'react-rater';
import HtmlTooltip from "../../../components/htmltooltip";
import Report from "./chooseReport";
import constants from "../../../utils/constants";
import { ToastContainer, toast } from 'react-toastify';
import SessionReport from "./sessionReport";
import History from '@material-ui/icons/History';
import SessionHistory from "./sessionHistory";
import ApiHelper from "../../../utils/api-helper";
import EuiProgressBar from "../../../eui-components/progressbar";
import CommonHelper from "../../../utils/common-helper";

const customerStatus = [3, 4, 5, 8, 9, 10, 11, 12, 13, 14, 15];

class MySessions extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sessionColumns:
                [
                    {
                        title: "Is Live",
                        field: "isLiveStream",
                        render: (rowData) => rowData.isLiveStream === 1 ? <i className="icon icon-wifi" style={{ fontSize: 20, marginLeft: 5 }}></i> : null,
                        filterCellStyle: { width: 70 },
                        width: "5%",
                        type: "boolean"
                    },
                    {
                        title: "Session ID",
                        field: "id",
                        width: "5%",
                        filterCellStyle: { width: 120, display: "inline-flex" },
                        defaultSort: "desc"
                    },
                    {
                        title: "Session Name",
                        field: "name",
                        width: "15%",
                    },
                    {
                        title: "Customer Name",
                        field: "customerName",
                        hidden: this.props.userData.loginRole === 1 ? true : false,
                    },
                    {
                        title: "Customer Project Name",
                        field: "customerProjectName",
                        hidden: this.props.userData.loginRole === 1 ? false : true,
                    },
                    {
                        title: "Customer Scope Identification",
                        field: "customerScopeIdentification",
                        hidden: this.props.userData.loginRole === 1 ? false : true,
                    },
                    {
                        title: "Site Code",
                        field: "siteCode",
                    },
                    {
                        title: "Session Type",
                        field: "type",
                        render: (rowData) => rowData.type === constants.customerSessionTypes.ECA ? rowData.type + " | " + rowData.ecaType : "ERITOP",
                    },
                    {
                        title: "Actions",
                        render: (rowData) => this.renderSessionActions(rowData),
                        width: "10%"
                    },
                    {
                        title: "Session Status", field: "status",
                        render: (rowData) => PageHelper.renderSessionStatus(rowData.status),
                        lookup: constants.statusLookup,
                    },
                    {
                        title: "Submited Date", field: "submitDate",
                        render: (rowData) => DateUtils.toDateString(rowData.submitDate, "YYYY-MM-DD HH:mm"),
                        filterCellStyle: { width: 180, display: "inline-flex" },
                    },
                    {
                        title: "Download Status", field: "downloadStatus",
                        hidden: true
                    },

                ],
            currentRow: { id: 0 },
            openDialog1: false, //Delete Dialog
            openDialog2: false, //Submit Dialog
            openDialog3: false, //View History Dialog
            featureAvailbiltyDialog : false,
            openFilter: true,
            openDialogDownload: false,
            openSessionReport: false,
            isSessionReportChanged: "",
            sessionReportFields: [],
            sessionReportName: "",
            reportID: "",
            openDialogSaveBar: false,
            percentageApi: 0 , 
            featureSubmitToECA : null,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({ isLoading: true }, () => {
            if (this.props.navbarData.navBarView) {
                this.props.toggleNavbar();
            }


            console.log("USERDATA " + JSON.stringify(this.props.userData));
            this.GetFeatures()
            ApiClient.post("Session/GetAllSession", { id: parseInt(this.props.userData.userData.userId) })
                .then(response => {
                    if (this._isMounted) {
                        this.setState({ isLoading: false }, () => {
                            let mySessions = [...response.data];
                            if (this.props.userData.loginRole === 1) {
                                mySessions = mySessions.filter(item => customerStatus.includes(item.status));
                            } else if (this.props.userData.loginRole === 3) {
                                mySessions = mySessions.filter(item => item.state !== 4);
                            }
                            this.props.setAllSession(mySessions);
                            this.props.setSessionInfo({
                                id: 0,
                                name: "",
                                customerId: 0,
                                //projectId: 0,
                                customerUserIds: [],
                                siteCode: "",
                                customerProjectName: "",
                                scopeOfWorkDefinition: "",
                                customerScopeIdentification: "",
                                createUser: "",
                                createDate: null,
                                siteCode: "",
                                updateUser: "",
                                updateDate: null,
                                viewOnly: false,
                            });
                            this.props.setApproveSession({
                                id: 0,
                                name: "",
                                viewOnly: false,
                                status: 2,
                                customerUsers: [],
                                responsibleUsers: []
                            });
                            this.props.setSessionActivityIds([]);
                        });
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                })
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    GetFeatures = () => {

        ApiClient.get("Common/FeaturesStatus")
        .then(response => {
            
            const responseValue = response.data
            const data = (JSON.parse(responseValue.data) ?? [])
            const submitToECA = data.find(v=> v.FeatureName == 'SUBMIT_TO_ECA')
            this.setState({featureSubmitToECA : submitToECA})
            debugger;
        })
        .catch(error => {
            console.error(error);;
            this.setState({ isLoading: false });
        })
    }
    renderSessionActions = (rowData) => {
        switch (rowData.status) {
            case 1:
                return <span>
                    <Tooltip id="finishSession" title="Finish Session">
                        <IconButton aria-label="finish" id="sessionFinish" onClick={() => this.finishSession}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip id="deleteSessionEdit" title="Delete Session">
                        <IconButton aria-label="delete" id="sessionDeleteEdit" onClick={() => this.setState({ currentRow: rowData })}>
                            <i className="icon icon-trashcan" style={{ fontSize: 20 }}></i>
                        </IconButton>
                    </Tooltip>
                </span>;
            case 2:
                return <span>
                    <Tooltip id="editSession" title="Edit Session">
                        <IconButton aria-label="edit" id="sessionEdit" onClick={() => this.editSession(rowData)}>
                            <i className="icon icon-edit" style={{ fontSize: 20 }}></i>
                        </IconButton>
                    </Tooltip>
                    {this.props.userData.loginRole == 3
                        ?
                        <Tooltip id="readyForSession" title="Ready For Submisson">
                            <span>
                                <IconButton aria-label="submit" id="sessionReadyFor" onClick={() =>{
                                    const feature = this.state.featureSubmitToECA
                                    debugger;
                                    feature?.Disabled ? this.setState({featureAvailbiltyDialog : true}) : this.setState({ currentRow: rowData, openDialog2: true })
                                    }
                                    }>
                                    <i className="icon icon-arrow-up" style={{ fontSize: 20 }}></i>
                                </IconButton>
                            </span>
                        </Tooltip>
                        :
                        <Tooltip id="submitSession" title="Submit Session">
                            <span>
                                <IconButton aria-label="submit" id="sessionSubmit" disabled={rowData.createUserRole === 3 && !rowData.readySubmitDate} onClick={() => {
                                    const feature = this.state.featureSubmitToECA
                                    debugger;
                                    feature?.Disabled ? this.setState({featureAvailbiltyDialog : true}) : this.setState({ currentRow: rowData, openDialog2: true })
                                }}>
                                    <i className="icon icon-send" style={{ fontSize: 20 }}></i>
                                </IconButton>
                            </span>
                        </Tooltip>
                    }

                    <Tooltip id="deleteSession" title="Delete Session">
                        <IconButton aria-label="delete" id="sessionDelete" onClick={() => this.setState({ currentRow: rowData, openDialog1: true })}>
                            <i className="icon icon-trashcan" style={{ fontSize: 20 }}></i>
                        </IconButton>
                    </Tooltip>
                </span>;
            case 3:
            case 11:
            case 12:
            case 13:
            case 14:
            case 15:
            case 100:
                return <span>
                    {this.props.userData.loginRole != 1 ? null :
                        <Tooltip id="acceptSession" title="Review&Approve Session">
                            <IconButton aria-label="accept" id="sessionAccept" onClick={() => this.acceptSession(rowData)}>
                                <DoneOutlineIcon />
                            </IconButton>
                        </Tooltip>

                    }
                    {this.props.userData.loginRole == 1 ? null :
                        <span>
                            <Tooltip id="viewSessionSubmitted" title="View Only">
                                <IconButton aria-label="view" id="sessionViewSubmitted" onClick={() => this.viewOnlySession(rowData, 3)}>
                                    <i style={{ fontSize: 20 }} className="icon icon-eye"></i>
                                </IconButton>
                            </Tooltip>
                        </span>
                    }
                </span>;
            case 4:
            case 101:
                return <span>
                    {this.props.userData.loginRole === 1
                        ?
                        <Tooltip id="viewSessionAccepted" title="View Only Session">
                            <IconButton aria-label="view" id="sessionViewRejected" onClick={() => this.viewOnlySession(rowData, 2)}>
                                <i style={{ fontSize: 20 }} className="icon icon-lock-locked"></i>
                            </IconButton>
                        </Tooltip>
                        :
                        <span>
                            <Tooltip id="editSession" title="Edit Session">
                                <IconButton aria-label="edit" id="sessionEdit" onClick={() => this.editSession(rowData)}>
                                    <i className="icon icon-edit" style={{ fontSize: 20 }}></i>
                                </IconButton>
                            </Tooltip>
                            <Tooltip id="submitSession" title="Submit Session">
                                <IconButton aria-label="submit" id="sessionSubmit" onClick={() => this.setState({ currentRow: rowData, openDialog2: true })}>
                                    <i className="icon icon-send" style={{ fontSize: 20 }}></i>
                                </IconButton>
                            </Tooltip>
                            <Tooltip id="deleteRejectSession" title="Delete Session">
                                <IconButton aria-label="delete" id="sessionRejectDelete" onClick={() => this.setState({ currentRow: rowData, openDialog1: true })}>
                                    <i className="icon icon-trashcan" style={{ fontSize: 20 }}></i>
                                </IconButton>
                            </Tooltip>
                        </span>
                    }
                </span>;
            case 5:
            case 8:
            case 9:
            case 10:
            case 102:
                return <span>
                    <HtmlTooltip
                        id="viewSessionAccepted"
                        title={
                            <React.Fragment>
                                <Rater rating={rowData.rating} total={5} interactive={false} />
                            </React.Fragment>
                        }
                    >
                        <IconButton aria-label="view" id="sessionViewAccepted" onClick={() => this.viewOnlySession(rowData, 2)}>
                            <i style={{ fontSize: 20 }} className="icon icon-lock-locked"></i>
                        </IconButton>
                    </HtmlTooltip>
                </span>;
            case 7:
                return <span>
                    {this.props.userData.loginRole == 3
                        ?
                        null
                        :
                        <span>
                            <Tooltip id="submitSession" title="Submit Session">
                                <span>
                                    <IconButton aria-label="submit" id="sessionSubmit" onClick={() => this.setState({ currentRow: rowData, openDialog2: true })}>
                                        <i className="icon icon-send" style={{ fontSize: 20 }}></i>
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip id="deleteSession" title="Delete Session">
                                <IconButton aria-label="delete" id="sessionDelete" onClick={() => this.setState({ currentRow: rowData, openDialog1: true })}>
                                    <i className="icon icon-trashcan" style={{ fontSize: 20 }}></i>
                                </IconButton>
                            </Tooltip>
                        </span>
                    }
                    <Tooltip id="viewSessionSubmitted1" title="View Only">
                        <IconButton aria-label="view" id="sessionViewSubmitted1" onClick={() => this.viewOnlySumittedSession(rowData)}>
                            <i style={{ fontSize: 20 }} className="icon icon-eye"></i>
                        </IconButton>
                    </Tooltip>
                </span>;
            default:
                return "";
        }
    }

    deleteSession = () => {
        this.setState({ openDialog1: false, isLoading: true }, () => {
            ApiClient.post("Session/DeleteSession", { id: this.state.currentRow.id, updateUser: this.props.userData.userData.userId })
                .then(response => {
                    if (!response.data.overallSuccess) {
                        this.setState({ isLoading: false });
                        var error = '';
                        try {
                            error = JSON.parse(response.data.errorMessage);
                            error = error.responseMessageDescription
                        } catch (e) {
                            error = 'Some issues occured while deleting session'
                        }
                        CommonHelper.createNotification({
                            title: "Error", description: error ,
                            icon: "icon-failed"
                        });
                    }
                    else {
                        ApiClient.post("Session/GetAllSession", { id: parseInt(this.props.userData.userData.userId) })
                            .then(response => {
                                this.setState({ isLoading: false }, () => this.props.setAllSession(response.data));
                            })
                            .catch(error => {
                                console.error(error);;
                                this.setState({ isLoading: false });
                            })
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                })
        });
    }

    onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentageApi = Math.floor((loaded * 100) / total);
        this.setState({ percentageApi, openDialogSaveBar: true });
    }

    submitSession = () => {
        const endPoint = this.state.currentRow.type === constants.customerSessionTypes.ECA ? "Session/SubmitECASession" : "Session/SubmitSession";
        const ecaEnvironment = constants.baseUrl === constants.prodURL ? 0 :
            this.props.userData.ecaConfig ? constants.ecaConfigurations.DEMO : constants.ecaConfigurations.QA
        this.setState({ openDialog2: false, isLoading: true }, () => {
            ApiClient.post(endPoint, {
                id: this.state.currentRow.id,
                updateUser: this.props.userData.userData.userId,
                ecaEnvironment
            }, {}, 1400000, this.onUploadProgress)
                .then(response => {
                    if (response.data) {
                        if (!response.data.overallSuccess) {
                        const errorMessage = response.data.errorMessage || 
                             "Some issues occured while ECA Integration, Please check history and contact with ECA & ERITOP Team [ericssoncustomeracceptancesupport@ericsson.com] about the issue.";
                            this.setState({ isLoading: false, openDialogSaveBar: false });
                            CommonHelper.createNotification({
                                title: "Error", description: response.data.errorMessage,
                                icon: "icon-failed"
                            });
                        }
                        else {
                            CommonHelper.createNotification({ title: "Success", description: "The session has been submitted successfully.", icon: "icon-success", timeout: 3000 });
                            ApiClient.post("Session/GetAllSession", { id: parseInt(this.props.userData.userData.userId) })
                                .then(response => {

                                    this.setState({ isLoading: false, openDialogSaveBar: false }, () => this.props.setAllSession(response.data));
                                })
                                .catch(error => {
                                    console.error(error);;
                                    this.setState({ isLoading: false, openDialogSaveBar: false });
                                })
                        }
                    } else {
                        this.setState({ isLoading: false, openDialogSaveBar: false });
                        const errorMessage = this.state.currentRow.type === constants.customerSessionTypes.ECA ? "Some issues occured while ECA Integration, Please check history and contact with ECA & ERITOP Team [ericssoncustomeracceptancesupport@ericsson.com] about the issue."
                            : "Some issues occured while submitting ERITOP session, please contact with ERITOP Support.";
                        CommonHelper.createNotification({
                            title: "Error", description: errorMessage,
                            icon: "icon-failed"
                        });
                        return;
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false, openDialogSaveBar: false });
                })
        });
    }

    acceptSession = (rowData) => {
        this.props.setApproveSession({ id: rowData.id, name: rowData.name, status: 2, customerUsers: rowData.customerUsers, responsibleUsers: rowData.responsibleUsers, viewOnly: rowData.viewOnly });
        this.props.history.push("/approve-session");
    }

    editSession = (rowData) => {
        if (rowData.status == 2) {

            let temp = { ...rowData };
            temp.updateDate = new Date();
            temp.updateUser = this.props.userData.userData.userId;
            temp.type = temp.type === constants.customerSessionTypes.ECA ? true : false;
            temp.ecaType = temp.ecaType === constants.ecaSessionType.AUTO ? true : false;
            temp.siteModal = { value: rowData.siteId, text: rowData.siteCode };
            this.props.setSessionInfo(temp);
            this.props.history.push("/create-session");
        } else if (rowData.status == 4 || rowData.status === 101) {
            const sessionRequest = { id: rowData.id, name: rowData.name, status: rowData.status, customerUsers: rowData.customerUsers, responsibleUsers: rowData.responsibleUsers, viewOnly: rowData.viewOnly };
            this.props.setApproveSession(sessionRequest);
            this.props.history.push("/approve-session");
        }
    }

    viewOnlySession = (rowData, status) => {
        const sessionRequest = { id: rowData.id, name: rowData.name, viewOnly: true, status, customerUsers: rowData.customerUsers, responsibleUsers: rowData.responsibleUsers };
        this.props.setApproveSession(sessionRequest);
        this.props.history.push("/approve-session");
    }

    viewOnlySumittedSession = (rowData) => {
        let temp = { ...rowData };
        temp.viewOnly = true;
        this.props.setSessionInfo(temp);
        this.props.history.push("/create-session");
    }

    openDownload = () => {
        this.setState({ openDialogDownload: true })
        this.props.getReport("photoReport")

    }

    checkBlobExist = (result, downloadLink, blobClient, rowData) => {
        PageHelper.blobExist(blobClient).then(_response => {

            if (_response) {
                clearInterval(result);
                toast.success("The report is sent to your email you can check your inbox!", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
                rowData.downloadStatus = false;
                this.setState({
                    currentRow: rowData
                })

                const link = document.createElement('a');
                link.href = downloadLink;
                document.body.appendChild(link);
                link.click();

            } else {

            }
        });
    }

    getReport = () => {
        if (this.props.navbarData.reportType === "photoReport") {
            this.setState({
                openDialogDownload: false,
                exportDone: false,

            })
            let rowData = { ...this.state.currentRow };
            ////rowData.downloadStatus = true;
            //this.setState({
            //    //hideLoading: false,
            //    currentRow: rowData
            //})

            toast.success("The report will be generated and sent to your Email, Please don't close this window!", { containerId: 'MS', position: toast.POSITION.BOTTOM_RIGHT });


            var DD = new Date();
            const _fileName_ = "PhotoReport-" + rowData.id + "-" + DD.toDateString() + "-" + DD.toLocaleTimeString() + ".xlsx";
            const fileName = _fileName_.split(' ').join('-');
            ApiClient.post("Session/GetActivitiesOfSiteAndId",
                { siteCode: rowData.siteCode, sessionId: rowData.id }
            ).then(response1 => {

                this.setState({
                    activities: response1.data
                })
                this.props.setSessionActivities(this.state.activities);
                if (this.state.activities.length != 0) {
                    this.state.activities[0]["subActivities"] = "SubActivities";
                    this.state.activities.map(item => {
                        item.subActivities = [];
                    });
                    ApiClient.post("Session/GetSubItemsOfActivity", this.props.sessionData.sessionActivities
                    ).then(response2 => {
                        let newActivityList = cloneDeep(response2.data);
                        PageHelper.mapActivityData(newActivityList);
                        this.props.setSessionSubActivities(cloneDeep(newActivityList));
                        //const containerClient = responseBlob.getContainerClient("reports");
                        //let resultTry = 0;
                        ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/ExportToExcel", { SubActivities: this.props.sessionData.sessionSubActivities, UserId: parseInt(this.props.userData.userData.userId), BlobName: fileName }, {}, 3000000
                        ).then(response3 => {
                            if (response3.data.result) {

                                this.setState({
                                    exportDone: true
                                });
                                toast.success("The report is generated and will be sent to your e-mail soon!", { containerId: 'MS', position: toast.POSITION.BOTTOM_RIGHT });
                                //const blobClient = containerClient.getBlobClient(response3.data.result.blobName);
                                //resultTry = setInterval(() => this.checkBlobExist(resultTry, response3.data.result.link, blobClient, rowData), 5000);
                            }
                        })
                            .catch(error => {
                                console.error(error);;
                                toast.success("The report is generated and will be sent to your e-mail soon!", { containerId: 'MS', position: toast.POSITION.BOTTOM_RIGHT });
                                //this.setState({
                                //    exportDone: true
                                //});
                                //const blobClient = containerClient.getBlobClient(fileName);
                                //
                                //const _link = "https://eritopasp1.blob.core.windows.net/reports/" + fileName
                                //const result = setInterval(() => this.checkBlobExist(result, _link, blobClient, rowData), 10000);
                            })
                    })
                }
            })
        }


        else if (this.props.navbarData.reportType === "observationSheet") {
            let rowData = this.state.currentRow
            if (rowData.status != 4 && rowData.status != 5) {
                toast.success("Session must be approved or rejected to get the observation sheet", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
                this.setState({ openDialogDownload: false })

            }
            else {
                var DD = new Date();
                var fileName = "ObservationSheet-" + rowData.id + "-" + DD.toDateString() + "-" + DD.toLocaleTimeString() + ".pdf";
                this.setState({ isLoading: true, openDialogDownload: false })
                ApiClient.post("Session/GetActivitiesOfSiteAndId",
                    { siteCode: rowData.siteCode, sessionId: rowData.id }
                ).then(response1 => {

                    this.setState({
                        activities: response1.data
                    })
                    this.props.setSessionActivities(this.state.activities);
                    this.state.activities[0]["subActivities"] = "SubActivities";
                    this.state.activities.map(item => {
                        item.subActivities = [];
                    });

                    ApiClient.post("Session/GetSubItemsOfActivity", this.props.sessionData.sessionActivities
                    ).then(response2 => {
                        let newActivityList = cloneDeep(response2.data);
                        PageHelper.mapActivityData(newActivityList);
                        this.props.setSessionSubActivities(cloneDeep(newActivityList));
                        ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/GenerateObservationSheet", { SubActivities: this.props.sessionData.sessionSubActivities, CustomerUserID: rowData.customerUsers[0].value }
                        ).then(response3 => {

                            if (response3.data != "" && response3.data != null) {
                                this.setState({
                                    isLoading: false
                                })
                                var FileSaver = require('file-saver');
                                const byteCharacters = atob(response3.data);
                                const byteNumbers = new Array(byteCharacters.length);

                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);

                                var blob = new Blob([byteArray], { type: 'application/pdf' });
                                FileSaver.saveAs(blob, fileName)
                            }
                        })
                    })
                })
            }
            //}
        }
        else if (this.props.navbarData.reportType === "detailedObservationSheet") {
            let rowData = this.state.currentRow
            if (rowData.status != 4 && rowData.status != 5) {
                toast.success("Session must be approved or rejected to get the observation sheet", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
                this.setState({ openDialogDownload: false })

            }
            else {
                var DD = new Date();
                var fileName = "DetailedObservationSheet-" + rowData.id + "-" + DD.toDateString() + "-" + DD.toLocaleTimeString() + ".pdf";
                this.setState({ isLoading: true, openDialogDownload: false })
                ApiClient.post("Session/GetActivitiesOfSiteAndId",
                    { siteCode: rowData.siteCode, sessionId: rowData.id }
                ).then(response1 => {

                    this.setState({
                        activities: response1.data
                    })
                    this.props.setSessionActivities(this.state.activities);
                    this.state.activities[0]["subActivities"] = "SubActivities";
                    this.state.activities.map(item => {
                        item.subActivities = [];
                    });

                    ApiClient.post("Session/GetSubItemsOfActivity", this.props.sessionData.sessionActivities
                    ).then(response2 => {
                        let newActivityList = cloneDeep(response2.data);
                        PageHelper.mapActivityData(newActivityList);
                        this.props.setSessionSubActivities(cloneDeep(newActivityList));
                        ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/GenerateDetailedObservationSheet", { SubActivities: this.props.sessionData.sessionSubActivities, CustomerUserID: rowData.customerUsers[0].value }
                        ).then(response3 => {

                            if (response3.data != "" && response3.data != null) {
                                this.setState({
                                    isLoading: false
                                })
                                var FileSaver = require('file-saver');
                                const byteCharacters = atob(response3.data);
                                const byteNumbers = new Array(byteCharacters.length);

                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);

                                var blob = new Blob([byteArray], { type: 'application/pdf' });
                                FileSaver.saveAs(blob, fileName)
                            }
                        })
                    })
                })
            }
        }
        else {
            let rowData = this.state.currentRow
            if (rowData.status != 4 && rowData.status != 5) {
                toast.success("Session must be approved or rejected to get the observation sheet", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
                this.setState({ openDialogDownload: false })

            }
            else {
                toast.success("The report will be generated and sent to your Email, Please don't close this window!", { containerId: 'MS', position: toast.POSITION.BOTTOM_RIGHT });
                var DD = new Date();
                var fileName = "SnagsReport-" + rowData.id + "-" + DD.toDateString() + "-" + DD.toLocaleTimeString() + ".xlsx";
                this.setState({ openDialogDownload: false })
                ApiClient.post("Session/GetActivitiesOfSiteAndId",
                    { siteCode: rowData.siteCode, sessionId: rowData.id }
                ).then(response1 => {

                    this.setState({
                        activities: response1.data
                    })
                    this.props.setSessionActivities(this.state.activities);
                    this.state.activities[0]["subActivities"] = "SubActivities";
                    this.state.activities.map(item => {
                        item.subActivities = [];
                    });

                    ApiClient.post("Session/GetSubItemsOfActivity", this.props.sessionData.sessionActivities
                    ).then(response2 => {
                        let newActivityList = cloneDeep(response2.data);
                        PageHelper.mapActivityData(newActivityList);
                        this.props.setSessionSubActivities(cloneDeep(newActivityList));
                        ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/GenerateExcelObservationSheet", { SubActivities: this.props.sessionData.sessionSubActivities, CustomerUserID: rowData.customerUsers[0].value, UserId: parseInt(this.props.userData.userData.userId) }
                        ).then(response3 => {

                            if (response3.data != "" && response3.data != null) {
                                toast.success("The report is sent to your email you can check your inbox!", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });

                                var FileSaver = require('file-saver');
                                const byteCharacters = atob(response3.data.result);
                                const byteNumbers = new Array(byteCharacters.length);

                                for (let i = 0; i < byteCharacters.length; i++) {
                                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                                }
                                const byteArray = new Uint8Array(byteNumbers);

                                var blob = new Blob([byteArray], { type: 'application/xlsx' });
                                FileSaver.saveAs(blob, fileName)
                            }
                        })
                    })
                })
            }
        }

    }

    sessionReport = () => {
        if (this.state.sessionReportFields == null || this.state.sessionReportName == "" || this.state.sessionReportName == "Create New Report") {
            toast.error("Please add the report first", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
        }
        else {
            toast.success("Report is being generated", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
            var DD = new Date();
            var fileName = this.state.sessionReportName.toUpperCase() + "_" + DD.toLocaleDateString() + "T" + DD.toLocaleTimeString() + ".xlsx";
            ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/GenerateSessionReport", { userID: parseInt(this.props.userData.userData.userId), Fields: this.state.sessionReportFields, ReportName: this.state.sessionReportName, Sessions: this.props.sessionData.allSession, ReportID: parseInt(this.state.reportID) }
            ).then(response => {
                if (this.state.isSessionReportChanged) {
                    ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/EditReportCols", { ReportID: parseInt(this.state.reportID), Fields: this.state.sessionReportFields })

                }

                if (response.data) {
                    if (response.data === "emptySession") {
                        toast.error("No sessions exist with these criteria!", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });

                    }
                    else {
                        var FileSaver = require('file-saver');
                        const byteCharacters = atob(response.data);
                        const byteNumbers = new Array(byteCharacters.length);

                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);

                        var blob = new Blob([byteArray], { type: 'application/xlsx' });
                        FileSaver.saveAs(blob, fileName)

                    }
                }
            })
        }

    }

    setSessionReport = (reportFields, reportName, reportID, reportChanged) => {
        this.setState({
            reportID: reportID,
            sessionReportFields: reportFields,
            sessionReportName: reportName,
            isSessionReportChanged: reportChanged
        })
    }

    viewHistory = () => {
        this.setState({ openDialog3: true });
    }

    getSessionTypeMessage = () => {
        const session = this.state.currentRow;
        if (session.type === constants.customerSessionTypes.ECA) {
            if (session.ecaType === constants.ecaSessionType.MANUAL) {
                return <b><span className="underline">ECA Portal</span> as <span className="underline">Manual</span> approval mode?</b>;
            } else {
                return <b>ECA Portal as Auto approval mode?</b>;
            }
        } else {
            return "ERITOP Portal?";
        }
    }

    render() {
        return (
            <EuiRow>
                <EuiTile styleContent={{ paddingRight: 15 }} >
                    {this.props.sessionData && this.props.sessionData.allSession ?
                        <Table
                            columns={this.state.sessionColumns}
                            data={this.props.sessionData.allSession}
                            isLoading={this.state.isLoading}
                            options={{
                                pageSize: 15,
                                //pageSizeOptions: [10],
                                cellStyle: { fontSize: "0.875rem", padding: 0, paddingLeft: 10 },
                                filtering: this.state.openFilter,
                                rowStyle: (rowData) => PageHelper.renderRow(this.state.currentRow && this.state.currentRow.id === rowData.id),
                                search: false,
                                paginationType: "stepped"
                            }}
                            onRowClick={(event, rowData) => this.setState({ currentRow: rowData })}
                            toolbar={{
                                leftCustom:
                                    this.state.currentRow && this.state.currentRow.id !== 0 ?
                                        <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-start" }}>
                                            <Tooltip title="View History">
                                                <IconButton style={{ margin: 5 }} aria-label="history" onClick={() => this.viewHistory()}>
                                                    <History style={{ fontSize: 22, marginTop: 5 }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Report">
                                                <IconButton aria-label="export" onClick={() => this.openDownload()}>
                                                    <i className="icon icon-download-save" style={{ fontSize: 20 }}></i>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        :
                                        null
                                ,
                                custom: <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
                                    <Tooltip title="Create Session Report">
                                        <IconButton style={{ margin: 2 }} aria-label="createSessionReport" onClick={() => this.setState({ openSessionReport: true })}>
                                            <i className="icon icon-document" style={{ fontSize: 20 }} ></i>
                                        </IconButton>
                                    </Tooltip>
                                    {this.props.userData.loginRole === 1 ?
                                        null
                                        :
                                        <Tooltip title="Create Session">
                                            <IconButton style={{ margin: 2 }} aria-label="filterSession" onClick={() => this.props.history.push("create-session")}>
                                                <i className="icon icon-plus" style={{ fontSize: 20 }}></i>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    <Tooltip title="Filter Sessions">
                                        <IconButton style={{ margin: 2, marginRight: 5 }} aria-label="createSession" onClick={() => this.setState({ openFilter: !this.state.openFilter })}>
                                            <i className="icon icon-filter" style={{ fontSize: 19, opacity: (this.state.openFilter ? "0.5" : "1") }}></i>
                                        </IconButton>
                                    </Tooltip>
                                </div>,
                            }}
                        >
                        </Table>
                        :
                        null
                    }

                    <DialogModal
                        buttonName="Delete"
                        content={<p>Are you sure you want to delete?</p>}
                        handleClose={() => this.setState({ openDialog1: false })}
                        id="deleteDialog"
                        onClick={this.deleteSession}
                        open={this.state.openDialog1}
                        title="Delete"
                        type="warning"
                        titleId="deleteTitle"
                    >
                    </DialogModal>

                    <DialogModal
                        buttonName="OK"
                        content={<p style={{ fontSize: 18 }}>Are you sure you want to submit session to {this.getSessionTypeMessage()}</p>}
                        handleClose={() => this.setState({ openDialog2: false })}
                        id="submitDialog"
                        onClick={this.submitSession}
                        open={this.state.openDialog2}
                        title={<span style={{ fontSize: 20 }}>Submit</span>}
                        type="primary"
                        titleId="submitTitle"
                    >
                    </DialogModal>

                    <DialogModal
                        buttonName="OK"
                        content={<p style={{ fontSize: 18 }}>{this.state.featureSubmitToECA?.DisplayNotes}</p>}
                        handleClose={() => this.setState({ featureAvailbiltyDialog: false })}
                        id="featureDialog"
                        onClick={()=>{this.setState({featureAvailbiltyDialog : false})}}
                        open={this.state.featureAvailbiltyDialog}
                        title={<span style={{ fontSize: 20, color : 'red' }}>Alert</span>}
                        type="primary"
                        titleId="feature"
                    >
                    </DialogModal>
                    

                    <DialogModal
                        buttonName="Generate Report"
                        content={<Report />}
                        handleClose={() => this.setState({ openDialogDownload: false })}
                        id="reportDialog"
                        onClick={this.getReport}
                        open={this.state.openDialogDownload}
                        title="Download"
                        type="primary"
                        titleId="ReportsTitle"
                    >
                    </DialogModal>

                    <DialogModal
                        buttonName="Export To Excel"
                        content={<SessionReport refs={this.sessionReportRef} report={this.setSessionReport} />}
                        handleClose={() => this.setState({ openSessionReport: false })}
                        id="sessionReportDialog"
                        onClick={this.sessionReport}
                        open={this.state.openSessionReport}
                        title="Session Report"
                        type="primary"
                        titleId="SessionReportsTitle"
                    >
                    </DialogModal>

                    <DialogModal
                        content={<SessionHistory sessionId={this.state.currentRow.id} />}
                        handleClose={() => this.setState({ openDialog3: false })}
                        id="historyDialog"
                        open={this.state.openDialog3}
                        title={this.state.currentRow.name + " History"}
                        type="primary"
                        titleId="historyTitle"
                        buttonsVisible="false"
                        fullScreen={true}
                    >
                    </DialogModal>


                    <DialogModal
                        content={<div key="ecaApiRequest" style={{ width: 500 }}>
                            <label><strong>{this.state.currentRow.name}</strong></label>
                            <EuiProgressBar value={this.state.percentageApi} />
                        </div>
                        }
                        handleClose={() => this.setState({ openDialogSaveBar: false })}
                        id="uploadBarECASubmit"
                        open={this.state.openDialogSaveBar}
                        buttonsVisible="false"
                        title="Progress Loading..."
                        closeButtonVisible="false"
                    >
                    </DialogModal>

                </EuiTile>
                <ToastContainer enableMultiContainer containerId={'MS'} />
            </EuiRow >
        );
    }
}

const mapStateToProps = state => ({ sessionData: state.session, userData: state.user, navbarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setSessionAllActivities: (activities) => dispatch({ type: "SET_SESSION_ALL_ACTIVITIES", payload: activities }),
        setSessionInfo: sessionInfo => dispatch({ type: "SET_SESSION_INFO", payload: sessionInfo }),
        setApproveSession: approveSession => dispatch({ type: "SET_APPROVE_SESSION", payload: approveSession }),
        setAllSession: allSession => dispatch({ type: "SET_ALL_SESSION", payload: allSession }),
        setSessionActivities: (activities) => dispatch({ type: "SET_SESSION_ACTIVITIES", payload: activities }),
        setSessionSubActivities: (subActivities) => dispatch({ type: "SET_SESSION_SUB_ACTIVITIES", payload: subActivities }),
        setSessionDocuments: (documents) => dispatch({ type: "SET_SESSION_DOCUMENTS", payload: documents }),
        getReport: reportType => dispatch({ type: "GET_REPORT", payload: reportType }),
        setSessionActivityIds: (ids) => dispatch({ type: "SET_SESSION_ACTIVITY_IDS", payload: ids }),
        setAllUsers: (users) => dispatch({ type: "SET_ALL_USERS", payload: users }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(MySessions)));
