import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import Rater from 'react-rater';
import DateUtils from "../../../utils/date-utils";
import AutoCompleteMultiSelect from "../../../components/autocompletemultiselect";
import EuiTextField from "../../../eui-components/textfield";
import DialogModal from "../../../components/dialog";
import AutoCompleteSelect from "../../../components/autocompleteselect";
import { Tooltip, IconButton } from "@material-ui/core";
import CreateEditWorkflow from "../../workflow/my-workflows/createEditWorkflow";
import EuiCheckbox from "../../../eui-components/checkbox";
import EuiDatepicker from "../../../eui-components/datepicker";
import EuiTimepicker from "../../../eui-components/timepicker";

class SessionInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openReviewWorkflow: false,
        }
    }

    componentDidMount() {
    }

    isSessionInfoDisabled = () => {
        return this.props.sessionData.approveSession.status === 2 || this.props.sessionData.approveSession.viewOnly;
    }

    reviewApproverUsers = () => {
        this.setState({ openReviewWorkflow: true });
    }

    render() {
        const session = this.props.session;
        return (
            <EuiRow>
                <EuiRow>
                    <div className="column sm-6 md-1 lg-1 xl-1 filled">
                        <FormField style={{ marginLeft: 0 }}><EuiTextField style={{ marginRight: 0, width: "100%" }}
                            label="Session ID"
                            labelstyle={{ fontWeight: 600 }}
                            name="sessionId"
                            value={this.props.session.id || ""}
                            disabled
                        /></FormField>
                    </div>
                    <div className="column sm-6 md-2 lg-2 xl-2 filled">
                        <FormField><EuiTextField style={{ marginRight: "0px", width: "100%" }}
                            label="Session Name"
                            labelstyle={{ fontWeight: 600 }}
                            name="name"
                            value={this.props.session.name || ""}
                            disabled
                            onChange={e => this.props.handleSessionInfoChange(e)}
                        /></FormField>
                    </div>
                    <div className="column sm-6 md-3 lg-3 xl-3 filled">
                        <FormField><EuiTextField style={{ marginRight: "0px", width: "100%" }}
                            label="Customer Project Name"
                            labelstyle={{ fontWeight: 600 }}
                            name="customerProjectName"
                            value={this.props.session.customerProjectName || ""}
                            disabled={this.isSessionInfoDisabled()}
                            onChange={e => this.props.handleSessionInfoChange(e)}
                        /></FormField>
                    </div>

                    <div className="column sm-6 md-3 lg-3 xl-3 filled">
                        <FormField>
                            <EuiTextField
                                style={{ marginRight: "0px", width: "100%" }}
                                label="Customer Scope Identification"
                                labelstyle={{ fontWeight: 600 }}
                                name="customerScopeIdentification"
                                value={this.props.session.customerScopeIdentification || ""}
                                disabled={this.isSessionInfoDisabled()}
                                onChange={e => this.props.handleSessionInfoChange(e)}
                            />
                        </FormField>
                    </div>
                    <div className="column sm-6 md-3 lg-3 xl-3 filled">
                        <FormField>
                            <EuiTextField
                                style={{ marginRight: "0px", width: "100%" }}
                                label="Scope of Work Description"
                                labelstyle={{ fontWeight: 600 }}
                                value={this.props.session.scopeOfWorkDescription || ""}
                                name="scopeOfWorkDescription"
                                disabled={this.isSessionInfoDisabled()}
                                onChange={e => this.props.handleSessionInfoChange(e)}
                            />
                        </FormField>
                    </div>
                </EuiRow>

                <EuiRow>
                    <div className="column sm-6 md-2 lg-2 xl-2 filled">
                        <FormField style={{ marginLeft: 0 }}><EuiTextField style={{ marginRight: "0px", width: "100%" }}
                            label="Submited Date"
                            labelstyle={{ fontWeight: 600, paddingBottom: 6 }}
                            name="submitDate"
                            value={DateUtils.toDateString(this.props.session.submitDate, "YYYY-MM-DD HH:mm") || ""}
                            disabled
                        /></FormField>
                    </div>
                    <div className="column sm-6 md-2 lg-2 xl-2 filled">
                        <FormField><EuiTextField style={{ marginRight: "0px", width: "100%" }}
                            label="Submited User"
                            labelstyle={{ fontWeight: 600, paddingBottom: 6 }}
                            name="submitUser"
                            value={this.props.session.submitUser || ""}
                            disabled
                        /></FormField>
                    </div>
                    <div className="column sm-6 md-2 lg-2 xl-2 filled">
                        <FormField><EuiTextField style={{ marginRight: "0px", width: "100%" }}
                            label="Approved Date"
                            labelstyle={{ fontWeight: 600, paddingBottom: 6 }}
                            name="approveDate"
                            value={DateUtils.toDateString(session.approveDate, "YYYY-MM-DD HH:mm") || ""}
                            disabled
                        /></FormField>
                    </div>
                    <div className="column sm-6 md-2 lg-2 xl-2 filled">
                        <FormField><EuiTextField style={{ marginRight: "0px", width: "100%" }}
                            label="Approved User"
                            labelstyle={{ fontWeight: 600, paddingBottom: 6 }}
                            name="approveUser"
                            value={this.props.session.approveUser || ""}
                            disabled
                        /></FormField>
                    </div>
                    <div className="column sm-6 md-4 lg-4 xl-4 filled">
                        <FormField style={{ marginTop: 8 }}>
                            <label style={{ fontWeight: 600 }} htmlFor="approveWorkflowId">Approval Flow</label>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <AutoCompleteSelect
                                    disabled={true}
                                    id="approveWorkflowId"
                                    name="workflowId"
                                    style={{ width: "70%" }}
                                    options={[this.props.session.workflow || { value: 0, text: "" }]}
                                    //onChange={e => this.handleChange(e)}
                                    value={this.props.session.workflow || { value: 0, text: "" }}
                                />
                                {(this.props.session.workflowId ?? 0) === 0 ?
                                    null :
                                    <Tooltip title="Review Approval Flow Details">
                                        <IconButton aria-label="review" onClick={() => this.reviewApproverUsers()} style={{ pointerEvents: "auto", opacity: "1" }}>
                                            <i className="icon icon-eye" style={{ fontSize: 18 }}></i>
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                        </FormField>
                    </div>
                </EuiRow>

                <EuiRow>
                    <FormField style={{ marginLeft: 0 }} >
                        <label style={{ fontWeight: 600 }} htmlFor="responsiblePeopleApprove">Session Responsible People</label>
                        <AutoCompleteMultiSelect
                            disabled={this.isSessionInfoDisabled() || this.props.sessionData.approveSession.status === 101}
                            id="responsiblePeopleApprove"
                            name="responsibleUsers"
                            style={{ width: "100%" }}
                            options={this.props.session.responsibleUsers || []}
                            value={this.props.responsibleUsers}
                            onChange={e => this.props.handleSessionInfoChange(e)}
                        />
                    </FormField>
                </EuiRow>

                <EuiRow>
                    <FormField style={{ marginLeft: 0 }} >
                        <label style={{ fontWeight: 600 }} htmlFor="customerProjectManagersApprove">Customer Project Managers</label>
                        <AutoCompleteMultiSelect
                            disabled={this.isSessionInfoDisabled() || this.props.sessionData.approveSession.status === 101}
                            id="customerProjectManagersApprove"
                            name="customerUsers"
                            style={{ width: "100%" }}
                            options={this.props.session.customerUsers || []}
                            value={this.props.customerUsers}
                            onChange={e => this.props.handleSessionInfoChange(e)}
                        />
                    </FormField>
                </EuiRow>
                <EuiRow>
                    <FormField>
                        <div style={{ display: "flex", alignItems: "center" }} className={this.isSessionInfoDisabled() ? "disabled" : ""}>
                            <EuiCheckbox name="isLiveStream"
                                id="isLiveStreamIdApprove"
                                label="Is Live Stream"
                                labelStyle={{ marginRight: 15 }}
                                refValue={this.props.session.isLiveStream === 1 ? true : false}
                                onChange={e => this.props.handleSessionInfoChange(e)} />
                            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                <EuiDatepicker
                                    id="liveStreamStartDateIdApprove"
                                    label="Start DateTime"
                                    dateStyle={{ marginRight: 6, marginLeft: 25 }}
                                    disabled={this.props.session.isLiveStream === 0}
                                    placeholder="YYYY-MM-DD"
                                    name="liveStreamStartDate"
                                    value={this.props.session.liveStreamStartDate}
                                    onChange={e => this.props.handleSessionInfoChange(e)}
                                />
                                <EuiTimepicker id="liveStreamStartTimeIdApprove"
                                    name="liveStreamStartTime"
                                    style={{ marginTop: 25, marginRight: 10 }}
                                    readonly={this.props.session.isLiveStream === 0}
                                    value={this.props.session.liveStreamStartTime}
                                    onChange={(e) => this.props.handleSessionInfoChange(e)}
                                />
                                <EuiDatepicker
                                    id="liveStreamEndDateIdApprove"
                                    label="End DateTime"
                                    disabled={this.props.session.isLiveStream === 0}
                                    dateStyle={{ marginRight: 6 }}
                                    placeholder="YYYY-MM-DD"
                                    name="liveStreamEndDate"
                                    value={this.props.session.liveStreamEndDate}
                                    onChange={e => this.props.handleSessionInfoChange(e)}
                                />
                                <EuiTimepicker id="liveStreamEndTimeIdApprove"
                                    name="liveStreamEndTime"
                                    style={{ marginTop: 25 }}
                                    readonly={this.props.session.isLiveStream === 0}
                                    value={this.props.session.liveStreamEndTime}
                                    onChange={(e) => this.props.handleSessionInfoChange(e)}
                                />
                            </div>
                        </div>
                    </FormField>
                </EuiRow>
                <EuiRow>
                    <div className="column sm-12 md-8 lg-8 xl-8 filled">
                        <FormField style={{ marginLeft: 0 }} >
                            <label style={{ fontWeight: 600 }} htmlFor="commentsApproveSession">
                                {(this.props.sessionData.approveSession.status === 4 || this.props.sessionData.approveSession.viewOnly ? "Customer" : "Ericsson") + " Comments"}
                            </label>
                            <textarea
                                disabled
                                id="commentsApproveSession"
                                className="fullwidth"
                                style={{ height: 150, overflow: "scroll" }}
                                label="Comments"
                                name="commentsApproveSession"
                                value={this.props.session.comments || ""}
                            />
                        </FormField>
                    </div>
                    <div className="column sm-12 md-4 lg-4 xl-4 filled">
                        {this.props.sessionData.approveSession.viewOnly ?
                            <FormField>
                                <label style={{ fontWeight: 600 }} htmlFor="approveSessionRating">Session Rating</label>
                                <Rater total={5}
                                    rating={this.props.session.rating}
                                    interactive={false}
                                />
                            </FormField>
                            : null
                        }
                    </div>
                </EuiRow>

                <DialogModal
                    content={
                        <EuiRow style={{ height: 600, position: "absolute", bottom: "8%" }}>
                            <CreateEditWorkflow
                                editMod={2}
                                workflow={this.props.session.workflow}
                                changeMod={() => this.setState({ openReviewWorkflow: false })}
                            />
                        </EuiRow>
                    }
                    contentStyle={{ height: 700, width: 900 }}
                    handleClose={() => this.setState({ openReviewWorkflow: false })}
                    id="approveReviewWorkflowId"
                    open={this.state.openReviewWorkflow}
                    title="Review Approval Flow Details"
                    titleId="approveReviewWorkflowTitle"
                    buttonsVisible="false"
                >
                </DialogModal>

            </EuiRow>
        );
    }
}

const FormField = (props) => (
    <div style={{ margin: 5, ...props.style }} className="field validate">
        {props.children}
    </div>
);

const mapStateToProps = state => ({ sessionData: state.session, userData: state.user });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SessionInformation);
