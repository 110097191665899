import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../../eui-components/row";
import PageHelper from "../../../utils/page-helper";
import ApiClient from "../../../utils/api-client";
import DialogModal from "../../../components/dialog";
import EuiFooter from "../../../layout/footer";
import EuiSelect from "../../../eui-components/select";
import EuiAccordion from "../../../eui-components/accordion";
import SeverityLevelDialog from "./severityLevelDialog";
import { ToastContainer, toast } from 'react-toastify';
import ApproveSessionDialog from "./approveSessionDialog";
import EuiProgressBar from "../../../eui-components/progressbar";
import CreateEditSessionActivity from "../create-session/createEditSessionActivity";
import SessionInformation from "./sessionInformation";
import ActivityDocuments from "./activityDocuments";
import SessionDocuments from "./sessionDocuments";
import RcaDocuments from "./rcaDocuments";
import constants from "../../../utils/constants";
import SimpleSlider from "../../../components/slider";
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import RecordingModal from "../../../components/recordingModal";
import SummaryDocuments from "./summaryDocuments";
import DocumentDialogModal from "../../../components/documentDialogModal";
import EuiSwitch from "../../../eui-components/switch";
import EuiTile from "../../../eui-components/tile";
import DownloadFilterModal from "../../../components/downloadFilterModal";
import { saveAs } from "file-saver";
import CommonHelper from "../../../utils/common-helper";
import DocumentLoadingModal from "../../../components/documentLoadingModal";
import SasHelper from "../../../utils/sas-helper";


class ApproveSessionContent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            session: {},
            selectedDocument: "",
            statusList: constants.statusList,
            openDialog: false,
            dialogType: 3, //rejected           
            rejectReason: 0,
            rejectDescription: "",
            imgIndex: 0,
            imgUrlLength: 0,
            allDocument: [],
            totalCount: "",
            openDialogPreview: false,
            approveSwitchRef: false,
            allSessionDocument: [],

            deletedActivityDocuments: [],
            deletedSessionDocuments: [],

            sessionComment: "",
            sessionRating: 5,

            percentageArr: [],
            openDialogUploadBar: false,

            openedAccordion: 0,

            openCreateEditActivity: false,
            newSessionActivity: {},

            customerUsers: [],
            responsibleUsers: [],
            expandActivities: false,

            rcaActivities: [],
            openDialogApproveSession: false,
            approvalCondition: 0,

            openDialogFilter: false,
            downloadFilter: {
                emptyChecklist: true,
                images: true,
                videos: true,
                pdfs: true,
                otherDocuments: true,
                accepted: true,
                rejected: true,
                pending: true,
                recordings: true
            },
            compressPercentage: 0,
            filteredDocuments: [],
            openDialogDownloadBar: false,
            allDocumentTypeSizes: { total: 0, images: 0, videos: 0, pdfs: 0, otherDocuments: 0, recordings: 0 },
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        if (this.props.sessionData.approveSession.id === 0) {
            return;
        }

        this.props.setRecordings([]);

        this.setState({ isLoading: true }, () => {
            ApiClient.post("Session/GetSession", { id: this.props.sessionData.approveSession.id })
                .then(response => {
                    const newSession = { ...response.data };
                    const approveSession = { ...this.props.sessionData.approveSession };
                    approveSession.currentSession = { ...newSession };
                    this.props.setApproveSession(approveSession);
                    this.props.setRecordings(PageHelper.getRecordingsOfActivities(newSession.activities));
                    
                    PageHelper.mapApproveSession(newSession);

                    if (this._isMounted) {
                        this.setState({
                            session: newSession,
                            isLoading: false,
                            customerUsers: this.props.sessionData.approveSession.customerUsers,
                            responsibleUsers: this.props.sessionData.approveSession.responsibleUsers,
                            expandActivities: true,
                        }, () => {
                            this.setActivityAndSessionDocuments();
                            this.setState({ rcaActivities: this.state.session.activities && this.state.session.activities.filter(item => item.rscScope === constants.rscScopeName) });
                        });
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                })
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.session != nextState.session ||
            this.state.isLoading != nextState.isLoading ||
            this.state.selectedDocument != nextState.selectedDocument ||
            this.state.allDocument != nextState.allDocument ||
            this.state.openDialogPreview != nextState.openDialogPreview ||
            this.state.approveSwitchRef != nextState.approveSwitchRef ||
            this.state.allSessionDocument != nextState.allSessionDocument ||
            this.state.openDialog != nextState.openDialog ||
            this.state.openDialogUploadBar != nextState.openDialogUploadBar ||
            this.state.percentage != nextState.percentage ||
            this.state.openedAccordion != nextState.openedAccordion ||
            this.state.openCreateEditActivity != nextState.openCreateEditActivity ||
            this.state.percentageArr != nextState.percentageArr ||
            this.state.customerUsers != nextState.customerUsers ||
            this.state.responsibleUsers != nextState.responsibleUsers ||
            this.state.deletedSessionDocuments != nextState.deletedSessionDocuments ||
            this.state.deletedActivityDocuments != nextState.deletedActivityDocuments ||
            this.state.openDialogApproveSession != nextState.openDialogApproveSession ||
            this.state.openDialogFilter != nextState.openDialogFilter ||
            this.state.downloadFilter != nextState.downloadFilter ||
            this.state.openDialogDownloadBar != nextState.openDialogDownloadBar ||
            this.state.compressPercentage != nextState.compressPercentage ||
            this.state.allDocumentTypeSizes != nextState.allDocumentTypeSizes
            ;
    }

    handleChange = (e, rowData, type = 1) => {
        this.setState({ selectedDocument: rowData }, () => {
            const target = e.target;
            const value = target.value;
            if (value === 3 || value === 2) {
                this.handleDialog(value);
            } else if (value == 1) {
                this.handlePending(value, type);
            } else if (value == 4) {
                toast.warn("Can'not change status as rework!", { toastId: "statusControl", containerId: 'P', position: toast.POSITION.BOTTOM_RIGHT });
            }
        })
    }

    handleDialog = (value) => {
        const comment = this.state.selectedDocument.customerComments;
        let rejectReason = 0;
        if (this.state.selectedDocument.severityLevel == 0) {
            rejectReason = value == 2 ? 1 : 4;
        } else {
            rejectReason = this.state.selectedDocument.severityLevel;
        }

        this.setState({
            openDialog: true,
            dialogType: value,
            rejectDescription: comment,
            rejectReason
        });
    }

    handlePending = (value, type) => {
        this.setState({
            rejectDescription: "",
            rejectReason: 0,
            dialogType: value
        }, () => {
            if (type == 1) {
                this.changeStatus();
            } else {
                this.changeStatusSession();
            }
        });
    }

    renderName = (rowData) => {
        const view = PageHelper.getDocumentType(rowData.type);
        let color = PageHelper.getDocumentTypeColor(rowData.type);
        if (color === "#242424" && this.props.navbarData.theme === "dark") {
            color = "#fff";
        }
        return <a key={rowData.id + "_name"} onClick={() => this.selectAndOpenDocument(rowData)} style={{ display: "flex", color }}>{view}<span style={{ paddingTop: 3 }}>{rowData.name}</span></a>;
    }

    renderStatus = (rowData, preview = "", type = 1) => {
        const status = type == 1 ? rowData.sessionAcceptanceStatus : rowData.acceptanceStatusId;
        const color = PageHelper.renderActivityStatusColor(status);
        return <div key={rowData.id + "_status"} className="flex-start-content w-10">
            <i className={"icon icon-alarm-level4 color-" + color} style={{ width: "14%" }}> </i>
            <EuiSelect
                style={{
                    background: "inherit", borderRadius: "0px", border: "none",
                    color: (this.state.openDialogPreview || this.props.navbarData.theme === "dark" ? "#fff" : "#242424"),
                    fontSize: (this.state.openDialogPreview ? 17 : "0.8rem")
                }}
                disabled={this.props.sessionData.approveSession.viewOnly || this.props.sessionData.approveSession.status === 4 || this.props.sessionData.approveSession.status === 101}
                optionListStyle={{ position: "inherit" }}
                options={this.state.statusList}
                value={status}
                name={"status" + rowData.id}
                id={"status" + rowData.id + preview}
                onChange={e => this.handleChange(e, rowData, type)}
            />
        </div>
    }

    toogleModal = () => {
        this.setState({
            openDialogPreview: false
        });
    }

    nextClick = (type = 1) => {
        this.setState(prevState => {
            return {
                imgIndex: prevState.imgIndex === prevState.imgUrlLength - 1 ? 0 : prevState.imgIndex + 1,
            };
        }, () => {
            const selectedDocument = type == 1 ? this.state.allDocument[this.state.imgIndex] : this.state.allSessionDocument[this.state.imgIndex];
            this.setState({ totalCount: (this.state.imgIndex + 1) + "/" + this.state.imgUrlLength, selectedDocument });
        });
    }

    prevClick = (type = 1) => {
        this.setState(prevState => {
            return {
                imgIndex: prevState.imgIndex === 0 ? prevState.imgUrlLength - 1 : prevState.imgIndex - 1,
            };
        }, () => {
            const selectedDocument = type == 1 ? this.state.allDocument[this.state.imgIndex] : this.state.allSessionDocument[this.state.imgIndex];
            this.setState({ totalCount: (this.state.imgIndex + 1) + "/" + this.state.imgUrlLength, selectedDocument })
        });
    }

    selectAndOpenDocument = (rowData) => {
        if (!rowData.documentPath) {
            toast.error("Can not open document before saving!", { toastId: "documentPathControl", containerId: 'P', position: toast.POSITION.TOP_CENTER });
            return;
        }

        //if (rowData.type == 1) {
        this.setImageTrack(rowData);
        //} else {
        //    this.setState({ selectedDocument: rowData }, () => { this.setState({ openDialogPreview: true }); });
        //}
    }

    setSelectedDocument = (rowData) => {
        
        this.setState({ selectedDocument: rowData });
    }

    setImageTrack = (slide) => {
        const allImages = slide.hasOwnProperty("sessionAcceptanceStatus") ? [...this.state.allDocument] : [...this.state.allSessionDocument];
        const imgUrlLength = allImages.length;
        let imgIndex = allImages.findIndex(item => item.id == slide.id);
        this.setState({ selectedDocument: slide, imgIndex, imgUrlLength, totalCount: (imgIndex + 1) + "/" + imgUrlLength }, () => { this.setState({ openDialogPreview: true }); });
    }

    renderFilePreview = () => {
        const allImages = this.state.selectedDocument.hasOwnProperty("sessionAcceptanceStatus") ? [...this.state.allDocument] : [...this.state.allSessionDocument];
        const type = this.state.selectedDocument.hasOwnProperty("sessionAcceptanceStatus") ? 1 : 2;
        const severityLevel = PageHelper.renderSeverityLevel(this.state.selectedDocument.severityLevel)
        const comments = this.state.selectedDocument.customerComments ?? "";
        return <SimpleSlider
            id="sessionSlider"
            slideData={allImages}
            currentSlide={this.state.selectedDocument}
            initialSlide={this.state.imgIndex}
            totalCount={this.state.totalCount}
            onNext={() => this.nextClick(type)}
            onPrev={() => this.prevClick(type)}
            onClose={this.toogleModal}
            status={this.renderStatus(this.state.selectedDocument, "preview", type)}
            comment={comments}
            severityLevel={severityLevel}
            setImageTrack={(slide) => this.setImageTrack(slide)}
            type={type}
        />
    }

    checkAllStatus = () => {
        const allDocument = this.getAllDocument(1);
        const allSessionDocument = this.getAllDocument(2);
        if ((allDocument.length != 0 &&
            allDocument.filter(item => (item.sessionAcceptanceStatus == 1 || item.sessionAcceptanceStatus == 4)).length > 0) ||
            (allSessionDocument.length != 0 &&
                allSessionDocument.filter(item => (item.acceptanceStatusId == 1 || item.acceptanceStatusId == 4)).length > 0)
        ) {
            return false;
        }
        return true;
    }

    approveSession = () => {
        if (!this.checkAllStatus()) {
            toast.warn(constants.processWarnMessage, { toastId: "approveControl", containerId: 'P', position: toast.POSITION.BOTTOM_RIGHT });
            return;
        }

        if (this.state.isLoading) {
            toast.error("Please wait, your transaction is in progress...", { containerId: 'P', position: toast.POSITION.TOP_CENTER });
            return;
        }

        this.setState({ isLoading: true, openedAccordion: 2, openDialogApproveSession: false }, () => {

            let newSession = { ...this.state.session };
            newSession.approveUser = this.props.userData.userData.userId;
            newSession.comments = this.state.sessionComment;
            newSession.rating = this.state.sessionRating;
            newSession.approveDate = new Date();
            newSession.approvalCondition = this.state.approvalCondition;
            newSession.workflow = null;

            if (newSession.activities.length > 0) {
                newSession.activities.map(item => {
                    delete item.tableRef;
                    if (item.subActivities.length > 0) {
                        item.subActivities.map(subItem => {
                            delete subItem.tableRef;
                            if (subItem.documents.length > 0) {
                                subItem.documents.map(document => {
                                    document.sessionAcceptedUser = document.sessionAcceptedUser && document.sessionAcceptedUser != "" ? this.props.userData.userData.userId : null;
                                    document.sessionRejectedUser = document.sessionRejectedUser && document.sessionRejectedUser != "" ? this.props.userData.userData.userId : null;
                                });
                            }
                        });
                    }
                })
            }

            if (newSession.sessionActivities && newSession.sessionActivities.length > 0) {
                newSession.sessionActivities.map(item => {
                    delete item.tableRef;
                });
            }

            

            ApiClient.post("Session/ApproveSession", { ...newSession }, {}, 1000000)
                .then(response => {
                    if (response.data) {
                        this.setState({ isLoading: false }, () => {
                            this.props.history.push("/my-customer-sessions");
                        });
                    } else {
                        this.setState({ isLoading: false });
                        toast.error(newSession.workflowId + constants.workflowNotification, { containerId: 'P', position: toast.POSITION.TOP_CENTER });
                        return;
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    rejectSession = () => {
        if (!this.checkAllStatus()) {
            toast.warn("Can not approve or rejected due to status is pending or rework!", { toastId: "rejectControl", containerId: 'P', position: toast.POSITION.BOTTOM_RIGHT });
            return;
        }

        if (this.state.isLoading) {
            toast.error("Please wait, your transaction is in progress...", { containerId: 'P', position: toast.POSITION.TOP_CENTER });
            return;
        }

        this.setState({ isLoading: true, openedAccordion: 2 }, () => {

            let newSession = { ...this.state.session };
            newSession.rejectUser = this.props.userData.userData.userId;
            newSession.comments = this.state.sessionComment;
            newSession.workflow = null;

            if (newSession.activities.length > 0) {
                newSession.activities.map(item => {
                    delete item.tableRef;
                    if (item.subActivities.length > 0) {
                        item.subActivities.map(subItem => {
                            delete subItem.tableRef;
                            if (subItem.documents.length > 0) {
                                subItem.documents.map(document => {
                                    document.sessionAcceptedUser = document.sessionAcceptedUser && document.sessionAcceptedUser != "" ? this.props.userData.userData.userId : null;
                                    document.sessionRejectedUser = document.sessionRejectedUser && document.sessionRejectedUser != "" ? this.props.userData.userData.userId : null;
                                });
                            }
                        });
                    }
                })
            }

            if (newSession.sessionActivities && newSession.sessionActivities.length > 0) {
                newSession.sessionActivities.map(item => {
                    delete item.tableRef;
                });
            }

            

            ApiClient.post("Session/RejectSession", { ...newSession }, {}, 1000000)
                .then(response => {
                    if (response.data) {
                        this.setState({ isLoading: false }, () => {
                            this.props.history.push("/my-customer-sessions");
                        });
                    } else {
                        this.setState({ isLoading: false });
                        toast.error(newSession.workflowId + constants.workflowNotification, { containerId: 'P', position: toast.POSITION.TOP_CENTER });
                        return;
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    saveAndFinishLater = () => {

        if (this.state.isLoading) {
            toast.error("Please wait, your transaction is in progress...", { containerId: 'P', position: toast.POSITION.TOP_CENTER });
            return;
        }

        let newSession = { ...this.state.session };
        newSession.updateUser = this.props.userData.userData.userId;
        newSession.workflow = null;

        if (newSession.activities.length > 0) {
            newSession.activities.map(item => {
                delete item.tableRef;
                if (item.subActivities.length > 0) {
                    item.subActivities.map(subItem => {
                        delete subItem.tableRef;
                        if (subItem.documents.length > 0) {
                            subItem.documents.map(document => {
                                document.sessionAcceptedUser = document.sessionAcceptedUser && document.sessionAcceptedUser != "" ? this.props.userData.userData.userId : null;
                                document.sessionRejectedUser = document.sessionRejectedUser && document.sessionRejectedUser != "" ? this.props.userData.userData.userId : null;
                            });
                        }
                    });
                }
            })
        }

        if (newSession.sessionActivities && newSession.sessionActivities.length > 0) {
            newSession.sessionActivities.map(item => {
                delete item.tableRef;
            });
        }

        this.setState({ isLoading: true, openedAccordion: 2 }, () => {
            ApiClient.post("Session/SaveAndFinishLaterSession", { ...newSession }, {}, 100000)
                .then(response => {
                    if (response.data) {
                        this.setState({ isLoading: false }, () => {
                            if (this.props.routing) {
                                toast.success("Your changes saved succesfully!", { containerId: 'P', position: toast.POSITION.TOP_CENTER })
                            } else {
                                this.props.history.push("/my-customer-sessions");
                            }
                        });
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    changeStatus = () => {
        const rowData = this.state.selectedDocument;
        const status = this.state.dialogType;
        let activities = [...this.state.session.activities];
        if (activities.length > 0) {
            activities.map(item => {
                if (item.activityId == rowData.activityId) {
                    if (item.subActivities.length > 0) {
                        item.subActivities.map(subItem => {
                            if (subItem.id == rowData.subActivityId) {
                                if (subItem.documents.length > 0) {
                                    subItem.documents.map(document => {
                                        if (document.id == rowData.id) {
                                            document.sessionAcceptanceStatus = status;
                                            document.severityLevel = this.state.rejectReason;
                                            document.customerComments = (status == 3 || status == 2) ?
                                                this.state.rejectDescription
                                                :
                                                ""
                                            document.sessionAcceptedDate = status == 2 ? new Date() : null;
                                            document.sessionAcceptedUser = status == 2 ? this.props.userData.userData.username : "";
                                            document.sessionRejectedDate = status == 3 ? new Date() : null;
                                            document.sessionRejectedUser = status == 3 ? this.props.userData.userData.username : "";

                                        }
                                    });
                                }
                            }
                        })
                    }
                }
            });
        }

        let allDocument = [...this.state.allDocument];
        if (allDocument.length > 0) {
            allDocument.map(document => {
                if (document.id == rowData.id) {
                    document.sessionAcceptanceStatus = status;
                    document.severityLevel = this.state.rejectReason;
                    document.customerComments = (status == 3 || status == 2) ?
                        this.state.rejectDescription
                        :
                        ""
                    document.sessionAcceptedDate = status == 2 ? new Date() : null;
                    document.sessionAcceptedUser = status == 2 ? this.props.userData.userData.username : "";
                    document.sessionRejectedDate = status == 3 ? new Date() : null;
                    document.sessionRejectedUser = status == 3 ? this.props.userData.userData.username : "";
                }
            })
        }

        let selectedDocument = { ...this.state.selectedDocument };
        selectedDocument.sessionAcceptanceStatus = status;
        selectedDocument.severityLevel = this.state.rejectReason;
        selectedDocument.customerComments = (status == 3 || status == 2) ?
            this.state.rejectDescription
            :
            ""
        selectedDocument.sessionAcceptedDate = status == 2 ? new Date() : null;
        selectedDocument.sessionAcceptedUser = status == 2 ? this.props.userData.userData.username : "";
        selectedDocument.sessionRejectedDate = status == 3 ? new Date() : null;
        selectedDocument.sessionRejectedUser = status == 3 ? this.props.userData.userData.username : "";

        this.setState(prevState => ({
            ...prevState,
            session: {
                ...prevState.session,
                activities
            },
            allDocument,
            selectedDocument
        }));
    }

    changeStatusSession = () => {
        const rowData = this.state.selectedDocument;
        const status = this.state.dialogType;
        let sessionActivities = [...this.state.session.sessionActivities];

        if (sessionActivities.length > 0) {
            sessionActivities.map(item => {
                if (item.id == rowData.sessionActivityId) {
                    if (item.documents.length > 0) {
                        item.documents.map(document => {
                            if (document.id == rowData.id) {

                                document.acceptanceStatusId = status;
                                document.severityLevel = this.state.rejectReason;
                                document.customerComments = (status == 3 || status == 2) ?
                                    this.state.rejectDescription
                                    :
                                    ""
                                document.updateDate = new Date();
                                document.updateUser = this.props.userData.userData.username;
                            }
                        })
                    }
                }
            });
        }

        let allSessionDocument = [...this.state.allSessionDocument];
        if (allSessionDocument.length > 0) {
            allSessionDocument.map(document => {
                if (document.id == rowData.id) {
                    document.acceptanceStatusId = status;
                    document.severityLevel = this.state.rejectReason;
                    document.customerComments = (status == 3 || status == 2) ?
                        this.state.rejectDescription
                        :
                        ""
                    document.updateDate = new Date();
                    document.updateUser = this.props.userData.userData.username;
                }
            });
        }

        let selectedDocument = { ...this.state.selectedDocument };
        selectedDocument.acceptanceStatusId = status;
        selectedDocument.severityLevel = this.state.rejectReason;
        selectedDocument.customerComments = (status == 3 || status == 2) ?
            this.state.rejectDescription
            :
            ""
        selectedDocument.updateDate = new Date();
        selectedDocument.updateUser = this.props.userData.userData.username;

        this.setState(prevState => ({
            ...prevState,
            session: {
                ...prevState.session,
                sessionActivities
            },
            allSessionDocument,
            selectedDocument
        }));
    }

    rejectDocument = () => {
        this.setState({ openDialog: false },
            () => {
                
                if (this.state.selectedDocument.sessionAcceptanceStatus) {
                    this.changeStatus()
                } else {
                    this.changeStatusSession()
                }
            }
        );
    }

    renderRow = (rowData) => {
        var selected = (this.state.selectedDocument && this.state.selectedDocument.id === rowData.id);
        var background = 'silver';
        if (this.props.navbarData.theme === "dark") {
            background = "#353535";
        }
        return {
            background: selected ? background : 'inherit',
        }
    }

    getAllDocument = (rowDataType = 1) => {
        if (rowDataType == 1) {
            if (!this.state.session ||
                !this.state.session.activities ||
                this.state.session.activities.length == 0 ||
                this.state.session.activities.map(item => item.subActivities).length == 0 ||
                this.state.session.activities.map(item => item.subActivities).reduce((a, b) => { return a.concat(b); }).map(subDoc => subDoc.documents).length == 0
            ) {
                return [];
            }
            const allDocument = [...this.state.session.activities
                .map(item => item.subActivities).reduce((a, b) => { return a.concat(b); })
                .map(subDoc => subDoc.documents).reduce((a, b) => { return a.concat(b) })];
            return allDocument;
        } else {
            if (!this.state.session ||
                !this.state.session.sessionActivities ||
                this.state.session.sessionActivities.length == 0 ||
                this.state.session.sessionActivities.map(item => item.documents).length == 0
            ) {
                return [];
            }
            const allSessionDocument = [...this.state.session.sessionActivities.map(item => item.documents).reduce((a, b) => { return a.concat(b); })];
            return allSessionDocument;
        }
    }

    totalStatus = (type, rowDataType = 1) => {
        const allDocument = this.getAllDocument(rowDataType);
        if (allDocument.length == 0) {
            return 0;
        }
        if (rowDataType == 1) {
            return allDocument.filter(item => item.sessionAcceptanceStatus == type).length;
        } else {
            return allDocument.filter(item => item.acceptanceStatusId == type).length;
        }
    }

    setActivityAndSessionDocuments = () => {
        const allDocument = this.getAllDocument(1);
        const allSessionDocument = this.getAllDocument(2);
        if (allDocument.length === 0 && allSessionDocument.length === 0) {
            return;
        }
        this.setState({ imgUrlLength: allDocument.length, allDocument, allSessionDocument }, () => {
            this.setDocumentsSizeByType();
        });
    }

    saveRejectSession = () => {

        if (this.state.isLoading) {
            toast.error("Please wait, your transaction is in progress...", { containerId: 'P', position: toast.POSITION.TOP_CENTER });
            return;
        }

        //Activity documents rework items & Session documents rework items
        let activityReworkDocuments = [];
        let sessionReworkDocuments = [];
        if (this.state.session.activities.length == 0 ||
            this.state.session.activities.map(item => item.subActivities).length == 0 ||
            this.state.session.activities.map(item => item.subActivities).reduce((a, b) => { return a.concat(b); }).map(subDoc => subDoc.documents).length == 0
        ) {
            activityReworkDocuments = [];
        } else {
            activityReworkDocuments = [...this.state.session.activities
                .map(item => item.subActivities).reduce((a, b) => { return a.concat(b); })
                .map(subDoc => subDoc.documents).reduce((a, b) => { return a.concat(b) })].filter(r => r.sessionAcceptanceStatus == 4);
            activityReworkDocuments = [...activityReworkDocuments, ...this.state.deletedActivityDocuments];
        }

        if (this.state.session.sessionActivities.length == 0 ||
            this.state.session.sessionActivities.map(item => item.documents).length == 0
        ) {
            sessionReworkDocuments = [];
        } else {
            sessionReworkDocuments = [...this.state.session.sessionActivities
                .map(item => item.documents)
                .reduce((a, b) => { return a.concat(b); })]
                .filter(r => r.acceptanceStatusId == 4);
            sessionReworkDocuments = [...sessionReworkDocuments, ...this.state.deletedSessionDocuments];
        }

        const session = { ...this.state.session };
        session.customerUsers = this.state.customerUsers;
        session.responsibleUsers = this.state.responsibleUsers;
        session.updateDate = new Date();
        session.updateUser = this.props.userData.userData.userId;
        session.submitUser = session.submitUserId;
        session.activities = null;
        session.sessionActivities = null;
        session.workflow = null;

        if (activityReworkDocuments.length === 0 && sessionReworkDocuments.length === 0 && !session) {
            this.props.history.push("/my-customer-sessions");
            return;
        }

        
        
        

        this.setState({ isLoading: true, openedAccordion: 2 }, () => {
            ApiClient.post("Session/SaveRejectedSession", { activityReworkDocuments, sessionReworkDocuments, session })
                .then(response => {
                    if (response.data) {
                        this.setState({ isLoading: false }, () => {
                            this.props.history.push("/my-customer-sessions");
                        });
                    } else {
                        this.setState({ isLoading: false }, () => {
                            
                        });
                    }
                }).catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                })
        });
    }

    getFooterButtons = () => {
        if (this.props.sessionData.approveSession.status === 2) {
            return [
                {
                    type: "btn warning", buttonType: "button", name: "Reject", id: "rejectSessionButton",
                    onClick: () => this.rejectSession(),
                    style: { marginTop: 10, marginBottom: 10 },
                    display: this.props.sessionData.approveSession.viewOnly,
                    disabled: !this.state.approveSwitchRef,
                },
                {
                    type: "btn primary", name: "Approve", id: "approveSessionButton",
                    style: { marginTop: 10, marginBottom: 10 },
                    onClick: () => this.setState({ openDialogApproveSession: true }),
                    display: this.props.sessionData.approveSession.viewOnly,
                    disabled: !this.state.approveSwitchRef,
                }
            ];
        } else if ((this.props.sessionData.approveSession.status === 4 || this.props.sessionData.approveSession.status === 101) && !this.props.sessionData.approveSession.viewOnly) {
            return [
                {
                    type: "btn primary", name: "Save", id: "approveSessionButtonSubmit",
                    onClick: () => this.saveRejectSession(),
                    style: { marginTop: 10, marginBottom: 10 }
                }
            ];
        }
    }

    addSubActivityDocument = (rowData, documents) => {
        
        this.setState({ openDialogUploadBar: false, percentageArr: [] }, () => {
            let activity = this.state.session.activities.find(item => item.activityId == rowData.activityId);
            if (activity) {
                if (activity.subActivities && activity.subActivities.length > 0) {
                    let subActivity = activity.subActivities.find(item => item.id == rowData.subActivityId);
                    if (subActivity) {

                        const activityDocuments = [...subActivity.documents, ...documents];

                        let subActivities = [...activity.subActivities];
                        const i = subActivities.findIndex(item => item.id == subActivity.id);
                        subActivity.documents = activityDocuments;
                        subActivities[i] = subActivity;

                        let activities = [...this.state.session.activities];
                        const index = activity.tableData.id;
                        activity.subActivities = subActivities;
                        activities[index] = activity;

                        this.setState(preState => ({
                            session: {
                                ...preState.session,
                                activities
                            },
                            allDocument: [...this.state.allDocument, ...documents]
                        }));
                    }
                }
            }
        });
    }

    addSessionDocument = (rowData, documents) => {
        this.setState({ openDialogUploadBar: false, percentageArr: [] }, () => {
            let activity = this.state.session.sessionActivities.find(item => item.id == rowData.sessionActivityId);
            if (activity) {
                //new session document array
                const sessionDocuments = [...activity.documents, ...documents];

                let sessionActivities = [...this.state.session.sessionActivities];
                const index = sessionActivities.findIndex(item => item.id == activity.id);
                activity.documents = sessionDocuments;
                sessionActivities[index] = activity;

                this.setState(prevState => ({
                    session: {
                        ...prevState.session,
                        sessionActivities
                    },
                    allSessionDocument: [...this.state.allSessionDocument, ...documents],
                })
                );
            }
        });
    }

    addSessionActivityDocument = (rowData, documents) => {
        this.setState({ isLoading: true, openDialogUploadBar: false }, () => {
            ApiClient.post("Session/AddSessionDocuments", documents)
                .then(response => {
                    
                    if (response.data) {
                        let activity = { ...rowData };
                        //new session document array
                        const sessionDocuments = [...activity.documents, ...response.data];
                        let sessionActivities = [...this.state.session.sessionActivities];
                        const index = sessionActivities.findIndex(item => item.id == activity.id);

                        activity.documents = sessionDocuments;
                        sessionActivities[index] = activity;

                        this.setState(prevState => ({
                            isLoading: false,
                            session: {
                                ...prevState.session,
                                sessionActivities
                            },
                            allSessionDocument: [...this.state.allSessionDocument, ...response.data]
                        }), () => {
                            const approveSession = { ...this.props.sessionData.approveSession };
                            approveSession.currentSession = { ...this.state.session };
                            this.props.setApproveSession(approveSession);

                            
                            toast.success("Session Documents was added succesfully.", { toastId: "addSessionDocuments", containerId: 'P', position: toast.POSITION.TOP_CENTER });
                        });
                    }
                })
                .catch(error => {
                    this.setState({ isLoading: false }, () => console.error(error));
                });
        });
    }

    addSessionActivity = () => {
        this.setState({ openCreateEditActivity: false, openDialogUploadBar: false, isLoading: true }, () => {
            ApiClient.post("Session/AddSessionActivity", this.state.newSessionActivity)
                .then(response => {
                    
                    if (response.data) {
                        let newSessionActivityArr = [response.data];
                        PageHelper.mapSessionActivities(newSessionActivityArr);
                        const documents = response.data.documents ?? [];

                        this.setState(prevState => ({
                            isLoading: false,
                            session: {
                                ...prevState.session,
                                sessionActivities: [...prevState.session.sessionActivities, ...newSessionActivityArr]
                            },
                            allSessionDocument: [...this.state.allSessionDocument, ...documents]
                        }),
                            () => {
                                toast.success("Session Activity was created succesfully.", { toastId: "sessionActivityAddApprove", containerId: 'P', position: toast.POSITION.TOP_CENTER });
                            });
                    } else {
                        this.setState({ isLoading: false }, () =>
                            toast.error("Some errors occur, Please try later!", { containerId: 'P', position: toast.POSITION.TOP_CENTER })
                        );
                    }
                })
                .catch(error => {
                    this.setState({ isLoading: false }, () => console.error(error));
                })
        });
    }

    //New added session activity document or added existing one session document.
    mapSessionActivityDocument = (uplodedFiles, rowData) => {
        return this.props.sessionData.approveSession.status === 2
            ?
            uplodedFiles.map((item, index) => {
                return {
                    name: item.name,
                    ParentEvidenceId: rowData.id,
                    acceptanceStatusId: 2,
                    severityLevel: 1,
                    sessionActivityId: rowData ? rowData.id : 0,
                    latitude: PageHelper.coord.latitude,
                    longitude: PageHelper.coord.longitude,
                    documentPath: item.documentPath,
                    customerComments: "",
                    ericssonComments: "",
                    createDate: new Date(),
                    createUser: this.props.userData.userData.userId,
                    updateDate: new Date(),
                    updateUser: this.props.userData.userData.username,
                    type: PageHelper.getType(item.type),
                    deleteDate: null,
                    deleteUser: null
                }
            })
            :
            this.state.newSessionActivity.documents.map((item, index) => ({
                ...item,
                documentPath: uplodedFiles[index].documentPath,
                latitude: PageHelper.coord.latitude,
                longitude: PageHelper.coord.longitude,
            }));
    }

    handleAddSessionActivity = () => {
        const files = [...this.state.newSessionActivity.documents.map(item => item.file).filter(doc => doc)];
        

        if (files.length === 0) {
            this.addEditSessionActivity();
            return;
        }

        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            toast.error(fileSize, { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            return;
        }

        PageHelper.getCoordinates();

        this.setState({ openCreateEditActivity: false, openDialogUploadBar: true, percentageArr: PageHelper.initializePercentageArr(files) }, () => {
            const onProgress = (progressEvent, file) => {
                let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                let percentageArr = [...this.state.percentageArr];
                const index = percentageArr.indexOf(file);
                percentageArr[index].percentage = percentage;
                this.setState({ percentageArr });
            }

            PageHelper.uploadItems(files, onProgress).then(uploadedFiles => {
                const documents = this.mapSessionActivityDocument(uploadedFiles, null);
                
                this.setState(prevState => ({
                    ...prevState,
                    newSessionActivity: {
                        ...prevState.newSessionActivity,
                        documents
                    }
                }),
                    () => this.addSessionActivity()
                );
            }).catch(error => {
                console.error(error);;
            });
        });
        
    }

    onSubmitAddSessionActivity = () => {
        
        const documents = [...this.state.newSessionActivity.documents];
        if (documents.length > 0) {
            if (documents.filter(item => item.tableData && item.tableData.editing).length > 0) {
                toast.error("Please finish edit document process!", { containerId: 'P', position: toast.POSITION.TOP_CENTER })
                return;
            }
        }

        this.handleAddSessionActivity()
    }

    closeOtherItem = (accordionType) => {
        const openedAccordion = accordionType === this.state.openedAccordion ? 0 : accordionType;
        this.setState({ openedAccordion });
    }

    setActivities = (activities) => {
        this.setState(prevState => ({
            ...prevState,
            session: {
                ...prevState.session,
                activities
            }
        }));
    }

    setAllDocument = (allDocument) => {
        this.setState({ allDocument });
    }

    setDeletedActivityDocuments = (deletedActivityDocuments) => {
        this.setState({ deletedActivityDocuments });
    }

    handleSessionInfoChange = (event) => {
        const target = event.target;
        const value = target.value ?? target.checked;
        const name = target.name;

        if (name === "responsibleUsers") {
            this.setState({ responsibleUsers: value });
        } else if (name === "customerUsers") {
            this.setState({ customerUsers: value });
        } else if (name === "isLiveStream") {
            this.setState(prevState => ({
                session: {
                    ...prevState.session,
                    [name]: value ? 1 : 0,
                    liveStreamStartDate: null,
                    liveStreamStartTime: null,
                    liveStreamEndDate: null,
                    liveStreamEndTime: null,
                }
            }));
        } else {
            this.setState(prevState => ({
                session: {
                    ...prevState.session,
                    [name]: value
                }
            }));
        }
    }

    uploadAndInsertItems = (rowData, files, insertData, mapData) => {
        this.setState({ openDialogUploadBar: true, percentageArr: PageHelper.initializePercentageArr(files) }, () => {
            const onProgress = (progressEvent, file) => {
                let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                let percentageArr = [...this.state.percentageArr];
                const index = percentageArr.indexOf(file);
                percentageArr[index].percentage = percentage;
                this.setState({ percentageArr });
            }

            PageHelper.uploadItems(files, onProgress).then(uploadedFiles => {
                insertData(rowData, mapData(uploadedFiles, rowData, this.props.userData.userData.userId));
            }).catch(error => {
                console.error(error);;
            });
        });
    }

    setSessionActivities = (sessionActivities, deleteUpdate = "") => {
        this.setState(prevState => ({
            ...prevState,
            session: {
                ...prevState.session,
                sessionActivities
            }
        }), () => {
            
            if (deleteUpdate !== "") {
                const approveSession = { ...this.props.sessionData.approveSession };
                approveSession.currentSession = { ...this.state.session };
                this.props.setApproveSession(approveSession);
                
            }
        });
    }

    setAllSessionDocument = (allSessionDocument) => {
        this.setState({ allSessionDocument });
    }

    setDeletedSessionDocuments = (deletedSessionDocuments) => {
        this.setState({ deletedSessionDocuments });
    }

    setOpenCreateEditActivity = () => {
        this.setState({ openCreateEditActivity: true })
    }

    getActivities = () => {
        return this.state.session && this.state.session.activities && this.state.session.activities.filter(item => item.rscScope !== constants.rscScopeName);
    }

    hideSessionActivity = () => {
        return (!this.state.session.sessionActivities || this.state.session.sessionActivities.length === 0 || this.state.allSessionDocument.length === 0) && this.props.userData.loginRole === 1;
    }

    hideLiveStreamingRecordings = () => {
        return (!this.props.liveStreamData.recordings || this.props.liveStreamData.recordings.length === 0) && this.props.userData.loginRole === 1;
    }

    getSelectedDocument = () => {
        const selectedDocument = { ...this.state.selectedDocument };
        selectedDocument.documentPath = SasHelper.addSasKey(selectedDocument.documentPath || selectedDocument.recordPath);
        selectedDocument.type = selectedDocument.type || 2;
        selectedDocument.uploadDate = selectedDocument.uploadDate || selectedDocument.createDate || selectedDocument.endDatetime;
        
        return selectedDocument;
    }

    handleDownloadFilter = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value || target.checked;

        

        const filter = { ...this.state.downloadFilter };
        filter[name] = value;

        this.setState({
            downloadFilter: filter
        });
    }

    downloadCustomerSession = () => {
        if ((this.state.allDocument || []).length === 0 && (this.state.allSessionDocument || []).length === 0) {
            toast.error("The session has no document to download", { containerId: "P", position: toast.TOP_CENTER });
            return;
        }
        
        
        
        const filteredActivityDocuments = PageHelper.filterCustomerFiles(this.state.downloadFilter, this.state.allDocument, 1);
        const filteredSessionDocuments = PageHelper.filterCustomerFiles(this.state.downloadFilter, this.state.allSessionDocument, 2);
        const recordings = this.state.downloadFilter.recordings ? this.props.liveStreamData.recordings : [];
        const filteredDocuments = [...filteredActivityDocuments, ...filteredSessionDocuments, ...recordings];
        
        this.setState({ openDialogFilter: false, openDialogDownloadBar: true, percentageArr: PageHelper.initializePercentageArr(filteredDocuments), isLoading: true, filteredDocuments });
        const onProgress = (progressEvent, file) => {
            let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
            let percentageArr = [...this.state.percentageArr];
            const index = percentageArr.indexOf(file);
            percentageArr[index].percentage = percentage;
            this.setState({ percentageArr });
        }
        PageHelper.downloadBlobAzure(filteredDocuments, onProgress)
            .then(response => {
                
                const fileArr = (response || []).filter(r => r).map(item => ({ contentAsBlob: item.contentAsBlob, copySource: item.copySource }));
                this.downloadAsZip(fileArr);
            })
            .catch(error => {
                this.setState({ openDialogDownloadBar: false, isLoading: false });
                console.error(error);;
                toast.error(constants.errorMessage, { containerId: "P", position: toast.POSITION.TOP_CENTER })
            });
    }

    downloadAsZip = (fileArr) => {
        const session = this.state.session;
        

        let pathArr = [];
        let path = "";
        const sessionName = session.id + "-" + session.name.replace(/[\/\\":*?<>|]/g, '');
        fileArr.map((item, index) => {
            const document = this.state.filteredDocuments.find(d => (d.documentPath || d.recordPath) === item.copySource);
            if (!document) {
                return;
            }
            const type = document.sessionAcceptanceStatus ? 1 :
                document.recordPath ? 3 : 2;

            if (type === 1) {
                const activity = session.activities.find(s => s.activityId === document.activityId);
                const activityName = activity?.activityName?.replace(/[\/\\":*?<>|]/g, '');
                const checklistName = document.subActivityName?.replace(/[\/\\":*?<>|]/g, '');
                const acceptanceStatus = constants.acceptanceStatusLookup[document.sessionAcceptanceStatus];
                path = sessionName + "\\" + "Session Activities" + "\\" + activityName + "\\" + checklistName + "\\" + acceptanceStatus + "\\" + document.name;
            } else if (type === 2) {
                const sessionActivity = session.sessionActivities.find(s => s.id === document.sessionActivityId);
                const sessionActivityName = sessionActivity?.name?.replace(/[\/\\":*?<>|]/g, '');
                const acceptanceStatus = constants.acceptanceStatusLookup[document.acceptanceStatusId];
                path = sessionName + "\\" + "Other Activities" + "\\" + sessionActivityName + "\\" + acceptanceStatus + "\\" + document.name;
            } else if (type === 3) {
                path = sessionName + "\\" + "Live Streaming Recordings" + "\\" + document.recordName + ".mp4";
            }
            
            pathArr = [...pathArr, { path, contentAsBlob: item.contentAsBlob }];
        });

        const zipArr = PageHelper.getZipArr(pathArr);

        
        Promise.all(zipArr.map(async (item, index) => {
            try {
                if (Object.keys(item.files)?.length === 0) {
                    return;
                }
                const content = await this.generateZip(item);
                
                saveAs(content, sessionName + "-" + (index + 1) + ".zip");
                this.setState({ isLoading: false, compressPercentage: 0 },
                    () => { toast.success((index + 1) + ". " + " session package downloaded successfully.", { containerId: "P", position: toast.POSITION.TOP_CENTER }); }
                );
            } catch (error) {
                console.error(error);;
                this.setState({ isLoading: false, compressPercentage: 0 },
                    () => { toast.error("Some issues occured while zipping, Please try with less data.", { containerId: "P", position: toast.POSITION.TOP_CENTER }); }
                );
            }
        })).then(response => {
            this.setState({ openDialogDownloadBar: false, compressPercentage: 0 });
        }).catch(error => {
            this.setState({ openDialogDownloadBar: false, compressPercentage: 0 });
        });
    }

    async generateZip(zip) {
        
        const updateCallback = (metadata) => {
            this.setState({ compressPercentage: metadata.percent.toFixed(0) });
        }
        return await zip.generateAsync({
            type: "blob",
            compression: "DEFLATE",
            compressionOptions: {
                level: 5
            }
        }, updateCallback);
    }

    setDocumentsSizeByType = () => {
        const allDocument = [...this.state.allDocument, ...this.state.allSessionDocument, ...this.props.liveStreamData.recordings];
        
        PageHelper.getPropertiesOfBlobAzure(allDocument)
            .then(response => {
                const files = [...(response || []).filter(item => item)];
                const allDocumentTypeSizes = PageHelper.getFilesSizeByType(files);
                
                this.setState({ allDocumentTypeSizes });
            })
            .catch(error => {
                console.error(error);;
            });
    }

    render() {
        const completedSize = this.state.percentageArr.filter(d => d.percentage === 100).length;
        const totalSize = this.state.filteredDocuments.length;
        return (
            <EuiTile title="Session Details"
                tileStyle={{ padding: 10 }}
                rightIcon={<button className={"btn primary " + (this.state.isLoading ? "loading disabled" : "")} onClick={() => this.setState({ openDialogFilter: true })} > Download Session</button>}
                headerStyle={{ paddingBottom: 5 }}
            >
                <EuiRow>
                    <EuiRow style={{ overflow: "scroll" }}>
                        <EuiAccordion id="approveActivityDocumentsAccordion" style={{ width: "100%" }} items={[
                            {
                                title: "Session Information",
                                titleIcon: <i className="icon icon-info" style={{ fontSize: 17, margin: "0px 5px" }}></i>,
                                titleStyle: { paddingLeft: 0, fontWeight: 700 },
                                titleIconStyle: { marginLeft: 0 },
                                contentStyle: { paddingLeft: 0 },
                                content: <SessionInformation
                                    session={this.state.session}
                                    customerUsers={this.state.customerUsers}
                                    responsibleUsers={this.state.responsibleUsers}
                                    handleSessionInfoChange={(e) => this.handleSessionInfoChange(e)}
                                />
                            },
                            {
                                title: "Activity Documents",
                                titleIcon: <i className="icon icon-document" style={{ fontSize: 17, margin: "0px 5px" }}></i>,
                                titleStyle: { paddingLeft: 0, fontWeight: 700 },
                                titleIconStyle: { marginLeft: 0 },
                                contentStyle: { paddingLeft: 0 },
                                content: <ActivityDocuments
                                    key="activity-document"
                                    activities={this.getActivities()}
                                    setActivities={(activities) => this.setActivities(activities)}
                                    allDocument={this.state.allDocument}
                                    deletedActivityDocuments={this.state.deletedActivityDocuments}
                                    setSelectedDocument={(rowData) => this.setSelectedDocument(rowData)}
                                    setAllDocument={(allDocument) => this.setAllDocument(allDocument)}
                                    setDeletedActivityDocuments={(deletedActivityDocuments) => this.setDeletedActivityDocuments(deletedActivityDocuments)}
                                    handleDialog={(status) => this.handleDialog(status)}
                                    handlePending={(status, type) => this.handlePending(status, type)}
                                    selectAndOpenDocument={(rowData) => this.selectAndOpenDocument(rowData)}
                                    renderName={(rowData) => this.renderName(rowData)}
                                    renderStatus={(rowData) => this.renderStatus(rowData)}
                                    uploadAndInsertItems={this.uploadAndInsertItems}
                                    addSubActivityDocument={this.addSubActivityDocument}
                                    renderRow={(rowData) => this.renderRow(rowData)}
                                    isLoading={this.state.isLoading}
                                    expandActivities={this.state.expandActivities}
                                />
                            },
                            {
                                title: "Session Documents",
                                titleIcon: <i className="icon icon-document" style={{ fontSize: 17, margin: "0px 5px" }}></i>,
                                titleStyle: { paddingLeft: 0, fontWeight: 700 },
                                titleIconStyle: { marginLeft: 0 },
                                contentStyle: { paddingLeft: 0 },
                                hidden: this.hideSessionActivity(),
                                content: <SessionDocuments
                                    session={this.state.session}
                                    setSessionActivities={this.setSessionActivities}
                                    allSessionDocument={this.state.allSessionDocument}
                                    deletedSessionDocuments={this.state.deletedSessionDocuments}
                                    setSelectedDocument={(rowData) => this.setSelectedDocument(rowData)}
                                    setAllSessionDocument={(allSessionDocument) => this.setAllSessionDocument(allSessionDocument)}
                                    setDeletedSessionDocuments={(deletedSessionDocuments) => this.setDeletedSessionDocuments(deletedSessionDocuments)}
                                    handleDialog={(status) => this.handleDialog(status)}
                                    handlePending={(status, type) => this.handlePending(status, type)}
                                    selectAndOpenDocument={(rowData) => this.selectAndOpenDocument(rowData)}
                                    renderName={(rowData) => this.renderName(rowData)}
                                    renderStatus={this.renderStatus}
                                    uploadAndInsertItems={this.uploadAndInsertItems}
                                    addSessionDocument={this.addSessionDocument}
                                    addSessionActivityDocument={this.addSessionActivityDocument}
                                    mapSessionActivityDocument={this.mapSessionActivityDocument}
                                    renderRow={(rowData) => this.renderRow(rowData)}
                                    isLoading={this.state.isLoading}
                                    setOpenCreateEditActivity={this.setOpenCreateEditActivity}
                                />
                            },
                            {
                                title: "Remote Customer Acceptance Activities",
                                titleIcon: <i className="icon icon-tasks" style={{ fontSize: 17, margin: "0px 5px" }}></i>,
                                titleStyle: { paddingLeft: 0, fontWeight: 700 },
                                titleIconStyle: { marginLeft: 0 },
                                contentStyle: { paddingLeft: 0 },
                                content: <RcaDocuments rcaActivities={this.state.rcaActivities}
                                    isLoading={this.state.isLoading}
                                />,
                                hidden: this.props.sessionData.approveSession.status === 2 && this.props.userData.loginRole === 1,
                            },
                            {
                                title: "Live Streaming Recordings",
                                titleIcon: <VideocamOutlinedIcon style={{ fontSize: 21, margin: "0px 5px" }} />,
                                titleStyle: { paddingLeft: 0, fontWeight: 700, },
                                titleIconStyle: { marginLeft: 0 },
                                contentStyle: { paddingLeft: 0 },
                                hidden: this.hideLiveStreamingRecordings(),
                                content: <RecordingModal openDialog={(rowData) => this.setState({ openDialogPreview: true, selectedDocument: rowData })} data={this.props.liveStreamData.recordings} />,
                            }
                        ]}
                        />
                    </EuiRow>

                    <SummaryDocuments reworkTotal={true}
                        totalStatus={(type, documentType) => this.totalStatus(type, documentType)}
                        hideSessionSummary={this.hideSessionActivity()}
                        style={{ margin: 0, marginBottom: 10, paddingBottom: 10 }}
                        titleStyle={{ padding: "0px 5px" }} />

                    <EuiFooter id="approveSessionFooter"
                        buttons={this.getFooterButtons()}
                        buttonsLeft={[
                            {
                                type: "btn",
                                buttonType: "button",
                                name: "Cancel",
                                id: "cancelSessionButton",
                                onClick: () => this.props.history.push("/my-customer-sessions"),
                                display: this.props.routing,
                                style: { marginTop: 10, marginBottom: 10 },
                            },
                            {
                                type: "btn", name: "Save&Finish Later", id: "saveSessionButton",
                                onClick: () => this.saveAndFinishLater(),
                                display: this.props.sessionData.approveSession.viewOnly || this.props.sessionData.approveSession.status === 4 || this.props.sessionData.approveSession.status === 101,
                                style: { marginTop: 10, marginBottom: 10 },
                            }
                        ]}
                        infoPart={
                            (this.props.sessionData.approveSession.viewOnly || this.props.sessionData.approveSession.status === 4 || this.props.sessionData.approveSession.status === 101) ? null :
                                <div>
                                    <EuiSwitch checked={this.state.approveSwitchRef}
                                        name="approveSwitch"
                                        onChange={(e) => this.setState({ approveSwitchRef: e.target.checked })}
                                        dataEnabled="I hereby acknowledge I have reviewed and validated all related documents before proceeding my approval or rejection to this submitted site session."
                                        dataDisabled="I hereby acknowledge I have reviewed and validated all related documents before proceeding my approval or rejection to this submitted site session."
                                        className="p-1"
                                    />

                                    {
                                        //<FormControlLabel
                                        //    style={{ margin: "5px 5px 0px 5px" }}
                                        //    control={
                                        //        <Switch
                                        //            checked={this.state.approveSwitchRef}
                                        //            onChange={(e) => this.setState({ approveSwitchRef: e.target.checked })}
                                        //            name="approveSwitch"
                                        //            color="primary"
                                        //            size="small"
                                        //        />
                                        //    }
                                        //    label=""
                                        ///>
                                        //<span>I hereby acknowledge I have reviewed and validated all related documents before proceeding my approval or rejection to this submitted site session.</span>
                                    }
                                    <ApproveSessionDialog getSessionComment={(sessionComment) => this.setState({ sessionComment })}
                                        getSessionRating={(sessionRating) => this.setState({ sessionRating })}
                                    />
                                </div>
                        }
                    ></EuiFooter>

                    <DialogModal
                        buttonName="Ok"
                        content={<SeverityLevelDialog rejectReason={this.state.rejectReason}
                            rejectDescription={this.state.rejectDescription}
                            getRejectReason={(rejectReason) => this.setState({ rejectReason })}
                            getRejectDescription={(rejectDescription) => this.setState({ rejectDescription })}
                        />}
                        handleClose={() => this.setState({ openDialog: false })}
                        id="rejectDialog"
                        titleId="titleRejectDialog"
                        onClick={this.rejectDocument}
                        open={this.state.openDialog}
                        title="Update"
                    >
                    </DialogModal>

                    {this.state.openDialogPreview ?
                        <DocumentDialogModal openDialogPreview={this.state.openDialogPreview}
                            handleClose={() => this.setState({ openDialogPreview: false })}
                            content={!this.state.selectedDocument.recordPath ? this.renderFilePreview() : null}
                            id="approveSessionDocumentView"
                            showLocation={!this.state.selectedDocument.recordPath}
                            selectedDocument={this.getSelectedDocument()}
                        >
                        </DocumentDialogModal>
                        :
                        null
                    }

                    <DialogModal
                        content={this.state.percentageArr.map(file => {
                            return <div key={file.name} style={{ width: 500 }}>
                                <label><strong>{file.name}</strong></label>
                                <EuiProgressBar value={file.percentage} />
                            </div>
                        })}
                        handleClose={() => this.setState({ openDialogUploadBar: false })}
                        id="uploadBarSessionApproveDialog"
                        titleId="titleuploadBarSessionApproveDialog"
                        open={this.state.openDialogUploadBar}
                        buttonsVisible="false"
                        title="Progress Loading..."
                        closeButtonVisible="false"
                    >
                    </DialogModal>

                    <DialogModal
                        buttonName="Create"
                        content={
                            <CreateEditSessionActivity
                                sessionActivity={{ sessionId: this.state.session.id, acceptanceStatusId: 4 }}
                                getNewSessionActivity={(newSessionActivity) => this.setState({ newSessionActivity })}
                            />
                        }
                        handleClose={() => this.setState({ openCreateEditActivity: false })}
                        id="addSessionActivityDialogApprove"
                        onClick={this.onSubmitAddSessionActivity}
                        open={this.state.openCreateEditActivity}
                        title="Create New Session Activity"
                        titleId="titleAddSessionApproveDialog"
                    >
                    </DialogModal>

                    <DialogModal
                        buttonName="Confirm"
                        content={<div style={{ width: 300 }}>
                            <EuiSelect
                                style={{ background: "transparent", borderRadius: "0px", fontSize: 14, color: "inherit" }}
                                optionListStyle={{ position: "inherit" }}
                                options={constants.approvalConditions}
                                value={this.state.approvalCondition}
                                name="approvalCondition"
                                id="approvalConditionId"
                                onChange={(e) => this.setState({ approvalCondition: e.target.value })}
                            />
                        </div>
                        }
                        handleClose={() => this.setState({ openDialogApproveSession: false })}
                        id="approveSessionConditionallyId"
                        onClick={this.approveSession}
                        open={this.state.openDialogApproveSession}
                        title="Approve Session"
                        titleId="approveSessionConditionally"
                    >
                    </DialogModal>

                    <DialogModal
                        buttonName="Ok"
                        customStyle={{ width: 700 }}
                        content={<DownloadFilterModal
                            downloadFilter={this.state.downloadFilter}
                            handleDownloadFilter={this.handleDownloadFilter}
                            allDocumentTypeSizes={this.state.allDocumentTypeSizes} />}
                        handleClose={() => this.setState({ openDialogFilter: false })}
                        id="customerDownloadSessionFilter"
                        onClick={this.downloadCustomerSession}
                        open={this.state.openDialogFilter}
                        title={"Filter Download Session | " + (this.state.allDocumentTypeSizes.total === 0 ? "Please wait total size calculating..."
                            : " Total Size: " + CommonHelper.bytesToSize(this.state.allDocumentTypeSizes.total))}
                        type="primary"
                    >
                    </DialogModal>

                    <DialogModal
                        content={<DocumentLoadingModal compressPercentage={this.state.compressPercentage}
                            session={this.state.session}
                            length={totalSize}
                            percentageArr={this.state.percentageArr} />}
                        handleClose={() => this.setState({ openDialogDownloadBar: false })}
                        id="sessionDownloadBarDialog"
                        open={this.state.openDialogDownloadBar}
                        buttonsVisible="false"
                        title={"Downloading Progress: " + CommonHelper.getPercentage(completedSize, totalSize)}
                        closeButtonVisible="false"
                        disableBackdropClick={true}
                    >
                    </DialogModal>

                    <ToastContainer enableMultiContainer containerId={'P'} autoClose={2500} />
                </EuiRow>

            </EuiTile>
        );
    }
}

const mapStateToProps = state => ({ sessionData: state.session, userData: state.user, navbarData: state.navigation, liveStreamData: state.liveStream, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        setSessionAllActivities: (activities) => dispatch({ type: "SET_SESSION_ALL_ACTIVITIES", payload: activities }),
        setSessionInfo: sessionInfo => dispatch({ type: "SET_SESSION_INFO", payload: sessionInfo }),
        setAllSession: allSession => dispatch({ type: "SET_ALL_SESSION", payload: allSession }),
        setApproveSession: approveSession => dispatch({ type: "SET_APPROVE_SESSION", payload: approveSession }),
        setRecordings: (recordings) => dispatch({ type: "SET_RECORDINGS", payload: recordings }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ApproveSessionContent));
