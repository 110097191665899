import React from "react";
import constants from "./constants";
import DateUtils from "./date-utils";
import UserHelper from "./user-helper";

export default class VehicleHelper {

    static vehicleStatus = { 1: "Created", 2: "Under Review", 3: "Approved", 4: "Rejected", 5: "Retired",6: "Unretired" };
    
    static vehicleStatusLookup = { "Created": 1, "Under Review": 2, "Approved": 3, "Rejected": 4, "Retired": 5 ,"Unretired": 6 };
    static LogTypeId = { "NONE": 0, "ACTIVITY": 1, "OHS": 2, "USERS": 3, "SITE": 4, "RESOURCE_CERTIFICATE":5, "RESOURCE": 6, "WORKPLAN":7, "PROJECT": 8, "CUSTOMER_SESSION": 9, "VEHICLE": 10, "TEAM" : 11 };

    static isVehicleCompliant = {
        0: 'Non-compliant' ,// 0 => false
        1: 'compliant' //     1=> true
      };
   
    static vehicleStatusOptions = [
        { value: 1, text: "Created" },
    { value: 2, text: "Under Review" },
    { value: 3, text: "Approved" },
    { value: 4, text: "Rejected" },
    { value: 5, text: "Retired" }
    ];
    static approvedRoles = [constants.userRoles.ASP_PROJECT_MANAGER, constants.userRoles.ASP_TEAM_LEADER, /*constants.userRoles.ERICSSON_IM*/];

    static vehicleTypesOptions = [
        { value: 1, text: "Pickup" },
        { value: 2, text: "SUV" },
        { value: 3, text: "Sedan" },
        { value: 4, text: "Mini-Van" },
        { value: 5, text: "CUV" },
    ];

    static vehicleTypesLookup = { 1: "Pickup", 2: "SUV", 3: "Sedan", 4: "Mini-Van", 5: "CUV" };

    static vehicleTypes = { "Pickup": 1, "SUV": 2, "Sedan": 3, "Mini-Van": 4, "CUV": 5 };
    static vehicleAction = { "CREATED": "CREATED", "UPDATED":  "UPDATED", "APPROVED":"APPROVED", "REJECTED":"REJECTED", "RETIRED": "RETIRED","SUBMITTED": "SUBMITTED","UNRETIRED": "UNRETIRED" };


    static inspectionStatus = { 1: "Created", 2: "Under Review", 3: "Approved", 4: "Rejected", 5: "Expired" };

    static vehicleHTMLTableStatus = {
        1: `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-yellow m-1"></i>${this.vehicleStatus[1]}</div>`,
        2: `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-blue m-1"></i>${this.vehicleStatus[2]}</div>`,
        3: `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-green m-1"></i>${this.vehicleStatus[3]}</div>`,
        4: `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>${this.vehicleStatus[4]}</div>`,
        5: `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-gray m-1"></i>${this.vehicleStatus[5]}</div>`,
    }

    static vehicleHTMLStatus = {
        1: <div className="flex-start-content m-1"><i className="icon icon-alarm-level5 color-yellow m-1"></i>{this.vehicleStatus[1]}</div>,
        2: <div className="flex-start-content m-1"><i className="icon icon-alarm-level5 color-blue m-1"></i>{this.vehicleStatus[2]}</div>,
        3: <div className="flex-start-content m-1"><i className="icon icon-alarm-level5 color-green m-1"></i>{this.vehicleStatus[3]}</div>,
        4: <div className="flex-start-content m-1"><i className="icon icon-alarm-level5 color-red m-1"></i>{this.vehicleStatus[4]}</div>,
        5: <div className="flex-start-content m-1"><i className="icon icon-alarm-level5 color-gray m-1"></i>{this.vehicleStatus[5]}</div>,
    }

    static isValidChassisNumber = (value) => {
        if (!value) {
            return false;
        } else if (!/[A-Za-z0-9]+/.test(value)) {
            return false;
        }
        return true;
    }

    static isValidPlateNumber = (value) => {
        if (!value) {
            return false;
        } else if (/^\S*$/.test(value)) {
            return false;
        }
        return true;
    }

    static mapVehicles = (vehicles) => {
        if ((vehicles || []).length === 0) {
            return [];
        }

        vehicles.forEach(item => {
            item.editModel = { id: item.vehicle.id, status:item.vehicle.vehicleStatus };
            item.inactivateModel = { id: item.vehicle.id, status:item.vehicle.vehicleStatus  };
            item.sentToRscModel = { id: item.vehicle.id, status:item.vehicle.vehicleStatus  };
            item.approveVehicleModel = { id: item.vehicle.id, status:item.vehicle.vehicleStatus ,isApproved: item.vehicle.vehicleStatus===3&&item.vehicle.isVehicleCompliant==="Compliant" };
            item.reviseModel = { id: item.vehicle.id, status: item.vehicle.vehicleStatus  };
        });
    }
    static mapVehiclesAspCompany = (vehicles) => {
        if ((vehicles || []).length === 0) {
            return [];
        }

        vehicles.forEach(item => {
            item.editModel = { id: item.id, status:item.vehicleStatus };
            item.inactivateModel = { id: item.id, status:item.vehicleStatus  };
            item.sentToRscModel = { id: item.id, status:item.vehicleStatus  };
            item.approveVehicleModel = { id: item.id, status:item.vehicleStatus ,isApproved: item.vehicleStatus===3&&item.isVehicleCompliant==="Compliant" };
            item.reviseModel = { id: item.id, status: item.vehicleStatus  };
        });
    }

    static hasModificationPermit = (jobRole) => {
        if (!jobRole) {
            return false;
        } else if ([constants.userRoles.ASP_TEAM_LEADER, constants.userRoles.ASP_PROJECT_MANAGER].includes(jobRole)) {
            return true;
        }
        return false;
    }

    static mapInspections = (inspections, users) => {
        if ((inspections || []).length === 0) {
            return [];
        }
        inspections.forEach(item => {
            
            item.createDate = DateUtils.toDateString(item.createDate, "YYYY-MM-DD HH:MM:SS");
            item.inspectionName = item.inspectionName ?? new Date(item.createDate).toLocaleString('default', { month: 'long' }) + "_inspection";
            item.createUser = UserHelper.getUser(users, item.createUserId)?.name;
            item.deleteInspection = item.id;
            item.editInspection = item.id;
            item.history = (item.vehicleInspectionHistory || []).map(log => {
                return {
                    timestamp: log.dateTimeUTC,
                    title: (log.logName + " | " + (UserHelper.getUser(users, log.userId)?.name ?? "")),
                    content: `<p style="white-space: pre-wrap;">${log.logDetail}</p>`
                }
            });
            item.sendToSme = item.id
            /// map create user id
            // item.createUser = 
        });
    }
    static inspectionHTMLTableStatus = {
        1: `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-yellow m-1"></i>${this.inspectionStatus[1]}</div>`,
        2: `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-blue m-1"></i>${this.inspectionStatus[2]}</div>`,
        3: `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-green m-1"></i>${this.inspectionStatus[3]}</div>`,
        4: `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>${this.inspectionStatus[4]}</div>`,
        5: `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-gray m-1"></i>${this.inspectionStatus[5]}</div>`,
    }

    static inspectionHTMLTableStatusValidation = (inspection) => {
        var approveRejectDateTime = new Date(inspection.approveRejectDateTime)
        var date = new Date((approveRejectDateTime.setDate(approveRejectDateTime.getDate() + 90)))
        const currentDate = new Date();


        switch (inspection.statusId) {
            case 1: return `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-yellow m-1"></i>${this.inspectionStatus[1]}</div>`;

            case 2: return `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-blue m-1"></i>${this.inspectionStatus[2]}</div>`;

            case 3: {
                if(date.getTime() > currentDate.getTime())
                {
                    return `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-green m-1"></i>${this.inspectionStatus[3]}</div>`;
                }
                else
                {
                    return `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-gray m-1"></i>${this.inspectionStatus[5]}</div>`;
                }
            }
            case 4: return `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>${this.inspectionStatus[4]}</div>`;

            case 5: return `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-gray m-1"></i>${this.inspectionStatus[5]}</div>`;
        }

    }

    static inspectionHTMLTableValid = (inspection) => {
        var approveRejectDateTime = new Date(inspection.approveRejectDateTime)
        var date = new Date((approveRejectDateTime.setDate(approveRejectDateTime.getDate() + 90)))
        var formatedDate = DateUtils.toDateString(date, "YYYY-MM-DD HH:mm")
        const currentDate = new Date();
        switch (inspection.statusId) {
            case 3:{
                if(date.getTime() > currentDate.getTime())
                {
                    return `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-green m-1"></i>${formatedDate}: Valid</div>`;
                }
                else
                {
                    return `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>${formatedDate}: Expired</div>`;
                }
            }
            case 5:
                return `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>${formatedDate}</div>`;

            default:
                return `<div>--</div>`
        }
    }
}