import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import constants from "../../../utils/constants";
import EuiHeader from "../../../eui-components/header";
import { useCallback } from "react";
import VehicleContent from "./vehicleContent";
import VehicleHelper from '../../../utils/vehicle-helper';
import EuiRow from "../../../eui-components/row";
import DialogModal from "../../../components/dialog";

const CreateEditVehicle = (props) => {
    const vehicleContentRef = useRef();
    const [warningDialog, setWarningDialog] = useState(false);

    const currentVehicle = useSelector(state => state.vehicle.currentVehicle);
    const mod = useSelector(state => state.vehicle.mod);
    const userJobRole = useSelector(state => state.user.userData.jobRole);
    const reduxDispatch = useDispatch();



    const getRightActions = useCallback(() => {
        if (mod === constants.pageMods.EDIT || mod === constants.pageMods.ADD||currentVehicle.vehicleStatus === VehicleHelper.vehicleStatusLookup["Approved"]) {
            return <div><button className={"btn "} style={{ margin: 5 }} onClick={() => reduxDispatch({ type: "CHANGE_VEHICLE_MOD", payload: constants.pageMods.LIST })}><i className="icon icon-cross p-1"></i>Cancel</button>
                <button className={"btn primary "} style={{ margin: 5 }} onClick={() => vehicleContentRef.current.checkIsVehicleCompliant()}><i className="icon icon-check p-1"></i>Save</button>
               {/* <button className={"btn primary "} style={{ margin: 5 }} onClick={() => {  props.openStatusDialog({ id: currentVehicle.id, open: true, type: VehicleHelper.vehicleStatusLookup.Approved })}}><i className="icon icon-cross p-1"></i>Change Status </button> */}
            </div>
        } else if (mod === constants.pageMods.APPROVE
            && userJobRole === constants.userRoles.RSC_COODINATOR
            && currentVehicle.vehicleStatus === VehicleHelper.vehicleStatusLookup["Under Review"]) {
            return <div><button className={"btn warning"} style={{ margin: 5 }} onClick={() => props.openStatusDialog({ id: currentVehicle.id, open: true, type: VehicleHelper.vehicleStatusLookup.Rejected })}><i className="icon icon-failed p-1"></i>Reject</button>
                <button className={"btn primary "} style={{ margin: 5 }} onClick={() => props.openStatusDialog({ id: currentVehicle.id, open: true, type: VehicleHelper.vehicleStatusLookup.Approved })}><i className="icon icon-success p-1"></i>Approve</button>
            </div>
        }
    }, [mod]);

    return (
        <>
            <EuiRow style={{ padding: 5 }} >
                <EuiHeader id="vehicleDetailHeaderId" rightActions={getRightActions()}>
                </EuiHeader>
                <VehicleContent ref={vehicleContentRef} setWarningDialog={setWarningDialog} getVehicleList={props.getVehicleList} />
            </EuiRow>
            <DialogModal
                    id="confirmSendToEhsSME"
                    title=""
                    content="vehicle is non-compliant!"
                    buttonName="Confirm"
                    handleClose={() => setWarningDialog(false)}
                    onClick={() =>vehicleContentRef.current.save()}                    
                    mainButtonStyle={{ backgroundColor: "red", borderColor: "red" }}
                    open={warningDialog}
                />
        </>
    );

}

export default CreateEditVehicle;