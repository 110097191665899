// id, name, address, email, phone, contact person, active, country id, country name, short name, system record id, erisite country id, create date, update date
import React, { useState, useEffect } from "react";
import {useSelector } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";

const AspCompanyInfo = () => {
    const aspStateMod = useSelector(state => state.asp.mode);
    const selectedASP = useSelector(state => state.asp.selectedASP);
    const selectedCountry = useSelector(state => state.asp.selectedCountry);
    const [ASP, setASP] = useState({ asp: null, selectedCountry: null});
    useEffect(() => {
        if (ASP != selectedASP) {
            setASP({ asp: selectedASP, selectedCountry: selectedCountry });
        }
    },
        [selectedASP, selectedCountry]);
    return (
        <EuiRow>
            {(aspStateMod === 'initial' || ASP.asp === null) ?
                <div className="tile sm-12 lg-12 xl-12" id="aspInfo">
                    <div className="flex-middle-content m-2 w-10" style={{ fontSize: "18px", color: "gray" }}>Select an ASP Company to preview information..</div>
                </div>
                :
                <div className="tile sm-12 lg-12 xl-12" id="aspInfo">
                    <EuiRow>
                        <EuiTextField
                            className="p-1 w-5"
                            fullwidth
                            readOnly
                            style={{ padding: 0, width: "350px", fontSize: "16px" }}
                            labelstyle={{ color: "gray" }}
                            id="aspId"
                            name="id"
                            label="ASP Company ID"
                            value={ASP.asp?.id || ""} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="aspName"
                        name="name"
                        label="ASP Company Name"
                        value={ASP.asp?.name || ""} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="aspName"
                        name="name"
                        label="ASP Company Short Name"
                        value={ASP.asp?.aspShortName || ""} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="countryName"
                        name="countryName"
                        label="Country Name"
                        value={ASP.selectedCountry || ""}
                    />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="address"
                        name="address"
                        label="ASP Company Address"
                        value={ASP.asp?.address || ""}
                    />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="email"
                        name="email"
                        label="Email"
                        value={ASP.asp?.email || ""} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        style={{ width: "350px", paddingRight: 0, paddingLeft: 0, paddingBottom: 0, fontSize: "16px" }}
                        id="contactPerson"
                        name="contactPerson"
                        label="Contact Person"
                        value={ASP.asp?.contactPerson || ""} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="phone"
                        name="phone"
                        label="Phone Number"
                        value={ASP.asp?.phone || ""} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="status"
                        name="status"
                        label="ASP Status"
                            value={ASP.asp?.active == 1 ? "Active" : "Not Active"} />
                        <EuiTextField
                            className="p-1 w-5"
                            fullwidth
                            readOnly
                            style={{ padding: 0, width: "350px", fontSize: "16px" }}
                            labelstyle={{ color: "gray" }}
                            id="vendorid"
                            name="vendorid"
                            label="Vendor ID"
                            value={ASP.asp?.vendorId || ""}
                        />
                        <EuiTextField
                            className="p-1 w-5"
                            fullwidth
                            readOnly
                            style={{ padding: 0, width: "350px", fontSize: "16px" }}
                            labelstyle={{ color: "gray" }}
                            id="systemrecordid"
                            name="systemrecordid"
                            label="System record ID"
                            value={ASP.asp?.systemrecordid || ""}
                        />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="createDate"
                        name="createDate"
                        label="Create Date"
                        value={DateUtils.toDateString(ASP.asp?.createDate) || ""}
                    />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="updateDate"
                        name="updateDate"
                        label="Update Date"
                        value={DateUtils.toDateString(ASP.asp?.updateDate) || ""}
                        />
                        
                    </EuiRow>
                    </div>}
        </EuiRow>
    )
}
export default (AspCompanyInfo)