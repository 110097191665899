import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Chip } from '@material-ui/core';

export default class AutoCompleteMultiSelect extends React.Component {

    onChange = (event, newValue) => {
        this.props.onChange && this.props.onChange({ target: { value: newValue, name: this.props.name } });
    }

    render() {

        return (
            <div >
                <Autocomplete
                    multiple
                    disabled={this.props.disabled}
                    disableClearable
                    forcePopupIcon={false}
                    id={this.props.id}
                    options={this.props.options}
                    getOptionLabel={(option) => { return (option.text ?? option.name) + (option.mail ? " - " + option.mail : "") }}
                    getOptionSelected={(option, value) => { return (value.value ?? value.id) === (option.value ?? option.id) }}
                    value={this.props.value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label={this.props.label}
                            placeholder={this.props.placeholder}
                        />
                    )}
                    onChange={(e, newValue) => this.onChange(e, newValue)}
                    style={this.props.style}
                    renderTags={(tagValue, getTagProps) => {
                        return tagValue.map((option, index) => (
                            <Chip {...getTagProps({ index })}
                                label={(option.text ?? option.name) + (option.mail ? " - " + option.mail : "")}
                                style={{ backgroundColor: (option.viewOnly ? "darkgray" : "#4caf50") }} />
                        ));
                    }}
                />
            </div>
        );
    }
}
