import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiTextField from "../../../eui-components/textfield";
import constants from "../../../utils/constants";
import EuiRow from "../../../eui-components/row";
import EuiTile from "../../../eui-components/tile";
import VehicleReducer from "./vehicleReducer";
import VehicleHelper from "../../../utils/vehicle-helper";
import EuiAutocompleteSelect from "../../../eui-components/select/autocompleteselect";
import { useReducer } from "react";
import EuiNumberField from "../../../eui-components/numberfield";
import EuiCheckbox from "../../../eui-components/checkbox";
import EuiDatepicker from "../../../eui-components/datepicker";
import CommonHelper from "../../../utils/common-helper";
import ApiClient from "../../../utils/api-client";
import { useImperativeHandle } from "react";
import EuiLoading from "../../../eui-components/loading";
import UserHelper from "../../../utils/user-helper";
import DateUtils from "../../../utils/date-utils";
import { Logs } from "../../../utils/Log";
import { isEmpty, isEqual } from "lodash";
import FormField from "../../../eui-components/formfield";
import AutoCompleteSelect from "../../../components/autocompleteselect";



const VehicleContent = forwardRef((props, ref) => {
const {setWarningDialog}=props;
    const currentVehicle = useSelector(state => state.vehicle.currentVehicle);
    const [vehicleReducer, dispatch] = useReducer(VehicleReducer, { ...currentVehicle });
    const [isLoading, changeIsLoading] = useState(false);
    const [isVehicleCompliant, setIsVehicleCompliant] = useState(vehicleReducer?.isVehicleCompliant);
    const [aspCompany, setAspCompany] = useState(0);
    const [country, setCountry] = useState(0);

    const [countries, setCountries] = useState([{id:0,name:""}]);
    const [aspCompanies, setAspCompanies] = useState([{id:0,name:""}]);
    
    
    const userId = useSelector(state => state.user.userData.userId);
    const users = useSelector(state => state.user.users);
    const mod = useSelector(state => state.vehicle.mod);
    const selectedAsp = useSelector(state => state.asp.selectedASP);

    const aspCompaniesAA = useSelector(state => state.asp.countryASPList)

    const reduxDispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "SET_VEHICLE", payload: currentVehicle });
    }, [currentVehicle.id]);
    useEffect(() => {
       const list=[]
        aspCompaniesAA.forEach(element => {
            list.push({id: element.id, name: element.element})
        });
        setCountries(list)
    }, []);

    const handleChangeCountry = (e,data) => {
        const target = e.target;
        var value = target.value;
        setCountry({ name: value.name, id: value.id })
        
        const list=[]
        const filter= aspCompaniesAA.filter(item=>item.id===value.id)[0]?.subList;
         filter.forEach(element => { list.push({id: element.value, name: element.text}) });
            setAspCompanies(list)
    }

    const handleChangeAspCompany = (e,data) => {
        const target = e.target;
        var value = target.value;
        setAspCompany({ name: value.name, id: value.id })

    }

    
    const getVehicleStatus = useCallback(() => {
        if (mod === constants.pageMods.VIEW_ONLY) {
            return VehicleHelper.vehicleHTMLStatus[vehicleReducer.vehicleStatus];
        }
     
    }, [currentVehicle.vehicleStatus]);

    useImperativeHandle(ref, () => ({
        save() {
            save();

        },
        checkIsVehicleCompliant(){
            checkIsVehicleCompliant();
        }
    }));
const createVehicleLog= (response) =>{

        const logInfo={};
        const data={};
        logInfo.LogName= "Vehicle" 
        logInfo.userId=parseInt(userId);
        logInfo.LogObjectId=response.objectId;// id of vehicle
        logInfo.LogAction=VehicleHelper.vehicleAction.CREATED ;
        logInfo.LogTypeId=VehicleHelper.LogTypeId['VEHICLE'];
        data.Name='create vehicle'
        data.createDatetimeUtc=response.createDatetimeUtc;
        data.createUserid=response.createUserid;
          Logs(logInfo,{data})
};
const updateVehicleLog= (request) =>{
    const newData={};
    const oldData={};
    const logInfo={};
    logInfo.LogName= "Vehicle" ;
    logInfo.userId=parseInt(userId);
    logInfo.LogObjectId=vehicleReducer.id;// id of vehicle
    logInfo.LogAction= VehicleHelper.vehicleAction.UPDATED ;
    logInfo.LogTypeId=VehicleHelper.LogTypeId['VEHICLE'];
    
    // new data is
    
    if(!isEqual( currentVehicle.chassisNumber, request.chassisNumber) ){
        newData.chassisNumber=request.chassisNumber;
        oldData.chassisNumber=currentVehicle.chassisNumber;

    }
    if(!isEqual(currentVehicle.emergencyEqpt25Fe, request.emergencyEqpt25Fe) ){
        newData.emergencyEqpt25Fe=!!request.emergencyEqpt25Fe;
        oldData.emergencyEqpt25Fe=!!currentVehicle.emergencyEqpt25Fe;

    }
    if(!isEqual( currentVehicle.emergencyEqpt25Fe, request.emergencyEqpt25Fe) ){
        newData.emergencyEqpt25Fe=!!request.emergencyEqpt25Fe;
        oldData.emergencyEqpt25Fe=!!currentVehicle.emergencyEqpt25Fe;

    }
    if(!isEqual( currentVehicle.emergencyEqptFaKit, request.emergencyEqptFaKit) ){
        newData.emergencyEqptFaKit=!!request.emergencyEqptFaKit;
        oldData.emergencyEqptFaKit=!!currentVehicle.emergencyEqptFaKit;

    }
    if(!isEqual( currentVehicle.emergencyEqptHighReflectorVest, request.emergencyEqptHighReflectorVest) ){
        newData.emergencyEqptHighReflectorVest=!!request.emergencyEqptHighReflectorVest;
        oldData.emergencyEqptHighReflectorVest=!!currentVehicle.emergencyEqptHighReflectorVest;

    }
    if(!isEqual( currentVehicle.fittedWithAbs, request.fittedWithAbs) ){
        newData.fittedWithAbs=!!request.fittedWithAbs;
        oldData.fittedWithAbs=!!currentVehicle.fittedWithAbs;

    }
    if(!isEqual( currentVehicle.fittedWithSeatBelt, request.fittedWithSeatBelt) ){
        newData.fittedWithSeatBelt=!!request.fittedWithSeatBelt;
        oldData.fittedWithSeatBelt=!!currentVehicle.fittedWithSeatBelt;

    }
    if(!isEqual( currentVehicle.fittedWithHeadRests, request.fittedWithHeadRests) ){
        newData.fittedWithHeadRests=!!request.fittedWithHeadRests;
        oldData.fittedWithHeadRests=!!currentVehicle.fittedWithHeadRests;

    }
    if(!isEqual( currentVehicle.fittedWithRolloverProtection, request.fittedWithRolloverProtection) ){
        newData.fittedWithRolloverProtection=!!request.fittedWithRolloverProtection;
        oldData.fittedWithRolloverProtection=!!currentVehicle.fittedWithRolloverProtection;

    }
    if(!isEqual( currentVehicle.fittedWithApprTyres, request.fittedWithApprTyres) ){
        newData.fittedWithApprTyres=!!request.fittedWithApprTyres;
        oldData.fittedWithApprTyres=!!currentVehicle.fittedWithApprTyres;
  }

    if(!isEqual( currentVehicle.fittedWithHeadsFreeKit, request.fittedWithHeadsFreeKit) ){
        newData.fittedWithHeadsFreeKit=!!request.fittedWithHeadsFreeKit;
        oldData.fittedWithHeadsFreeKit=!!currentVehicle.fittedWithHeadsFreeKit;
    }  
   
  
    if(!isEqual( currentVehicle.gpsInstalled, request.gpsInstalled) ){
        newData.gpsInstalled=!!request.gpsInstalled;
        oldData.gpsInstalled=!!currentVehicle.gpsInstalled;

    }
    if(!isEqual( currentVehicle.gpsSystemAccessGranted, request.gpsSystemAccessGranted) ){
        newData.gpsSystemAccessGranted=!!request.gpsSystemAccessGranted;
        oldData.gpsSystemAccessGranted=!!currentVehicle.gpsSystemAccessGranted;

    }
    if(!isEqual( currentVehicle.gpsLiveTracking, request.gpsLiveTracking) ){
        newData.gpsLiveTracking=!!request.gpsLiveTracking;
        oldData.gpsLiveTracking=!!currentVehicle.gpsLiveTracking;

    }
    if(!isEqual( currentVehicle.isVehicleCompliant, request.isVehicleCompliant) ){
        newData.isVehicleCompliant=request.isVehicleCompliant;
        oldData.isVehicleCompliant=currentVehicle.isVehicleCompliant;

    }
    if(!isEqual( currentVehicle?.kmReading, request?.kmReading) ){
        newData.kmReading=request?.kmReading;
        oldData.kmReading=currentVehicle?.kmReading;

    }
    if(!isEqual( currentVehicle.lastModifiedDatetimeUtc, request.lastModifiedDatetimeUtc) ){
        newData.lastModifiedDatetimeUtc=request.lastModifiedDatetimeUtc;
        oldData.lastModifiedDatetimeUtc=currentVehicle.lastModifiedDatetimeUtc;

    }
    if(!isEqual( currentVehicle.lastServiceDatetime, request.lastServiceDatetime) ){
        newData.lastServiceDatetime=request.lastServiceDatetime;
        oldData.lastServiceDatetime=currentVehicle.lastServiceDatetime;

    }
    if(!isEqual( currentVehicle.lastServiceKmReading, request.lastServiceKmReading) ){
        newData.lastServiceKmReading=request.lastServiceKmReading;
        oldData.lastServiceKmReading=currentVehicle.lastServiceKmReading;

    }
    if(!isEqual( currentVehicle.modifiedUserComment, request.modifiedUserComment) ){
        newData.modifiedUserComment=request.modifiedUserComment;
        oldData.modifiedUserComment=currentVehicle.modifiedUserComment;

    }

    if(!isEqual( currentVehicle.registrationPlateNo, request.registrationPlateNo) ){
        newData.registrationPlateNo=request.registrationPlateNo;
        oldData.registrationPlateNo=request.registrationPlateNo;

    }
    if(!isEqual( currentVehicle.vehicleComplianceStatus, request.vehicleComplianceStatus) ){
        newData.vehicleComplianceStatus=request.vehicleComplianceStatus;
        oldData.vehicleComplianceStatus=currentVehicle.vehicleComplianceStatus;

    }
    if(!isEqual( currentVehicle.vehicleInspections, request.vehicleInspections) ){
        newData.vehicleInspections=request.vehicleInspections;
        oldData.vehicleInspections=currentVehicle.vehicleInspections;

    }
    if(!isEqual( currentVehicle.vehicleManufacturer, request.vehicleManufacturer) ){
        newData.vehicleManufacturer=request.vehicleManufacturer;
        oldData.vehicleManufacturer=currentVehicle.vehicleManufacturer;

    }
    if(!isEqual( currentVehicle.vehicleModelYear, request.vehicleModelYear) ){
        newData.vehicleModelYear=request.vehicleModelYear;
        oldData.vehicleModelYear=currentVehicle.vehicleModelYear;

    }
   
    
    if(!isEqual( currentVehicle.vehicleType, request.vehicleType) ){
        newData.vehicleType=request.vehicleType;
        oldData.vehicleType=currentVehicle.vehicleType;

    }
    if(!isEqual( currentVehicle.vehicleModelName, request.vehicleModelName) ){
        newData.vehicleModelName=request.vehicleModelName;
        oldData.vehicleModelName=currentVehicle.vehicleModelName;

    }
    if(!isEqual( currentVehicle.vehicleStatus, request.vehicleStatus) ){
        newData.vehicleStatus=request.vehicleStatus;
        oldData.vehicleStatus=currentVehicle.vehicleStatus;

    }
    if(!isEqual( currentVehicle.driverBehaviourMonitor, request.driverBehaviourMonitor) ){
        newData.driverBehaviourMonitor=!!request.driverBehaviourMonitor;
        oldData.driverBehaviourMonitor=!!currentVehicle.driverBehaviourMonitor;
    }
    if(!isEqual( currentVehicle.mileage, request.mileage) ){
        newData.mileage=request.mileage;
        oldData.mileage=currentVehicle.mileage;
    }
    ///console.log("currentVehicle,,request,,",currentVehicle,request)
    Logs(logInfo,{
      oldData,
      oldDataDate:vehicleReducer.createDatetimeUtc,
      newData,  
   })
};

   function save(){

        const checkCreateEditVehicle = () => {
            if (!vehicleReducer.chassisNumber ||
                !vehicleReducer.registrationPlateNo ||
                !vehicleReducer.vehicleManufacturer ||
                !vehicleReducer.vehicleModelName ||
                !vehicleReducer?.vehicleModelName ||
                !vehicleReducer?.vehicleModelYear ||
                !vehicleReducer?.kmReading ||
                !vehicleReducer?.lastServiceKmReading ||
                !vehicleReducer?.lastServiceDatetime ||
                !vehicleReducer?.mileage
            ) {
                CommonHelper.createNotification({ title: "Please fill mandatory fields.", icon: "icon-cross", timeout: 3000 });
                return false;
            }
            if(parseInt(vehicleReducer.mileage)<parseInt(vehicleReducer?.kmReading)){
                CommonHelper.createNotification({ title: "next service milleage should be greater than current km reading.", icon: "icon-cross", timeout: 6000 });
                return false;    }
            return true;
        }

        if (!checkCreateEditVehicle()) {
            return;
        }

        const vehicleRequest = { ...vehicleReducer };

   


        if (vehicleReducer.id === 0) {
            vehicleRequest.createUserid = parseInt(userId);
            vehicleRequest.vehicleStatus = 1;
        } else {
        
            vehicleRequest.modifiedUserid = parseInt(userId);
        }
if(!isEmpty(aspCompany)){
    vehicleRequest.aspCompanyId = aspCompany.id;

}else{
    vehicleRequest.aspCompanyId = selectedAsp.id;

}
        vehicleRequest.vehicleType = VehicleHelper.vehicleTypesLookup[vehicleReducer.vehicleType] || vehicleReducer.vehicleType;
        const now=new Date();



        if((vehicleRequest.lastServiceKmReading)> 350000 )
        {
            vehicleRequest.vehicleComplianceStatus="Invalid  >350K" 

        }else if((now.getFullYear()- vehicleRequest.vehicleModelYear) > 15)
        {
            vehicleRequest.vehicleComplianceStatus="Invalid >15YR" 

        }else if(((now.getFullYear()- vehicleRequest.vehicleModelYear)<=  15)&&((now.getFullYear()- vehicleRequest.vehicleModelYear) >= 10)&&((vehicleRequest.lastServiceKmReading) < 350000))
        {
            vehicleRequest.vehicleComplianceStatus="Exemption"
        }
        else if(((now.getFullYear()- vehicleRequest.vehicleModelYear) < 10) ||( (vehicleRequest.lastServiceKmReading) < 350000))
        {
            vehicleRequest.vehicleComplianceStatus="Revision C" 
        } 
      

        
        vehicleRequest.createDatetimeUtc=now;
        changeIsLoading(true);
                let request = {
                    ...vehicleRequest ,
                }
         ApiClient.post("Vehicle/CreateEditVehicle",{...request})
            .then(response => {
                if (response.data?.success) {
                    changeIsLoading(false);
                    setWarningDialog(false);
                    CommonHelper.createNotification({ title: "Vehicle has been" + (vehicleReducer.id === 0 ? " created " : " edited ") + "successfuly.", icon: "icon-check", timeout: 3000 });
                    const vehicles = JSON.parse(response.data.responseDetail || []);
               
                    VehicleHelper.mapVehiclesAspCompany(vehicles);
                    reduxDispatch({ type: "SET_VEHICLE_LIST", payload: vehicles });
                    reduxDispatch({ type: "SET_CURRENT_VEHICLE", payload: vehicleReducer });
                    reduxDispatch({ type: "CHANGE_VEHICLE_MOD", payload: constants.pageMods.LIST });
                    ///console.log("currentVehiclerequest",vehicleReducer,currentVehicle,request)

                    vehicleReducer.id === 0 ?  createVehicleLog(response.data):  updateVehicleLog(request) ;


                } else {
                    changeIsLoading(false);
                    CommonHelper.createNotification({ title: response.data.errorMessage, icon: "icon-cross" });
                }
                setWarningDialog(false);

            }).catch(error => {
                changeIsLoading(false);
                setWarningDialog(false);
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross" });
                console.error(error);;
            });
    }
    const checkIsVehicleCompliant= () => {
        ///console.log("vehicleReducer.roadworthyAndInsured",vehicleReducer.roadworthyAndInsured)
        if(
            vehicleReducer.roadworthyAndInsured===1&&
            vehicleReducer.fittedWithSeatBelt===1&&
            vehicleReducer.fittedWithHeadRests===1&&
            vehicleReducer.fittedWithApprTyres===1&&
            vehicleReducer.emergencyEqptTriAngles===1&&
            vehicleReducer.emergencyEqpt25Fe ===1&&
            vehicleReducer.emergencyEqptFaKit===1&&
            vehicleReducer.emergencyEqptHighReflectorVest===1&&
            vehicleReducer.gpsInstalled===1&&
            vehicleReducer.gpsLiveTracking ===1){
            vehicleReducer.isVehicleCompliant="Compliant"
            setWarningDialog(false);
            save()
            }else{
            setWarningDialog(true);
            vehicleReducer.isVehicleCompliant="Non-Compliant"
           return false; 
            }
    }
   
    const getUserModificationsSection = useCallback(() => {
        if (mod === constants.pageMods.VIEW_ONLY) {
            const createUser = UserHelper.getUser(users, vehicleReducer.createUserid);
            const submitUser = UserHelper.getUser(users, vehicleReducer.submitUserid);
            const approveUser = UserHelper.getUser(users, vehicleReducer.vehicleApprovedUserid);
            return <>
                <div className="tile-title" style={{ marginTop: 0 }}>User Modifications</div>
                <div className="content tile-bordered mt-2" style={{ paddingLeft: 2 }}>
                    <EuiRow>
                        <div className="column sm-12 md-6 lg-2 xl-2 filled">
                            <EuiTextField className="p-1"
                                fullwidth
                                readOnly={true}
                                id="vehicleCreateUserid"
                                name="vehicleCreateUser"
                                label="Created By"
                                value={createUser?.name}
                                additionalInfo={createUser?.mail}
                            />
                        </div>
                        <div className="column sm-12 md-6 lg-2 xl-2 filled">
                            <EuiTextField className="p-1"
                                fullwidth
                                readOnly={true}
                                id="vehicleCreateDatetimeUtcId"
                                name="createDatetimeUtc"
                                label="Created Datetime"
                                value={DateUtils.toDateString(vehicleReducer.createDatetimeUtc, "YYYY-MM-DD HH:mm:ss")}
                            />
                        </div>
                        {submitUser &&
                            <>
                                <div className="column sm-12 md-6 lg-2 xl-2 filled">
                                    <EuiTextField className="p-1"
                                        fullwidth
                                        readOnly={true}
                                        id="vehicleSubmitUserid"
                                        name="vehicleSubmitUser"
                                        label="Submitted By"
                                        value={submitUser?.name}
                                        additionalInfo={submitUser?.mail}
                                    />
                                </div>
                                <div className="column sm-12 md-6 lg-2 xl-2 filled">
                                    <EuiTextField className="p-1"
                                        fullwidth
                                        readOnly={true}
                                        id="vehicleSubmitDatetimeUtcId"
                                        name="submitDatetimeUtc"
                                        label="Submitted Datetime"
                                        value={DateUtils.toDateString(vehicleReducer.submitDatetimeUtc, "YYYY-MM-DD HH:mm:ss")}
                                    />
                                </div>
                            </>
                        }
                        {approveUser &&
                            <>
                                <div className="column sm-12 md-6 lg-2 xl-2 filled">
                                    <EuiTextField className="p-1"
                                        fullwidth
                                        readOnly={true}
                                        id="vehicleApprovedUserid"
                                        name="vehicleApprovedUser"
                                        label="Approved By"
                                        value={approveUser?.name}
                                        additionalInfo={approveUser?.mail}
                                    />
                                </div>
                                <div className="column sm-12 md-6 lg-2 xl-2 filled">
                                    <EuiTextField className="p-1"
                                        fullwidth
                                        readOnly={true}
                                        id="vehicleApprovedDatetimeUtcId"
                                        name="approvedDatetimeUtc"
                                        label="Approved Datetime"
                                        value={DateUtils.toDateString(vehicleReducer.approvedDatetimeUtc, "YYYY-MM-DD HH:mm:ss")}
                                    />
                                </div>
                            </>
                        }
                        <div className="column sm-12 md-12 lg-12 xl-12 ">
                            <EuiTextField 
                              className="p-1"
                                fullwidth
                                multiLine={true}
                                readOnly={true}
                                id="vehicleModifiedUserCommentId"
                                name="modifiedUserComment"
                                label="Modified User Comment"
                                value={vehicleReducer.modifiedUserComment}      
                            />
                        </div>
                    </EuiRow>
                </div>
            </>
        }
    }, [mod]);

    useEffect(() => {
        if( vehicleReducer.roadworthyAndInsured===1&&
            vehicleReducer.fittedWithSeatBelt===1&&
            vehicleReducer.fittedWithApprTyres===1&&
            vehicleReducer.fittedWithHeadRests===1&&
            vehicleReducer.emergencyEqptTriAngles===1&&
            vehicleReducer.emergencyEqpt25Fe ===1&&
            vehicleReducer.emergencyEqptFaKit===1&&
            vehicleReducer.emergencyEqptHighReflectorVest===1&&
            vehicleReducer.gpsInstalled===1&&
            vehicleReducer.gpsLiveTracking ===1)
            {
             setIsVehicleCompliant("Compliant")
            }
            else{
             setIsVehicleCompliant("Non-compliant")
            }
    }, [vehicleReducer])
    
    return (
        <>
            <EuiTile title= {"Vehicle Id : " +vehicleReducer.id } contentClassName="tile-bordered" className={isLoading ? " disabled " : ""}>
                <EuiLoading id="vehicleDetailLoading" isLoading={isLoading} />
                <EuiRow>
                    <div className="column sm-12 md-4 lg-4 xl-4 filled">
                        {
                            getVehicleStatus()
                        }
                        <EuiAutocompleteSelect 
                            id="vehicleTypeSelect"
                            className="p-2"
                            fullwidth
                            style={{ padding: 3 }}
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            name="vehicleType"
                            label={{ title: "Vehicle Type *" }}
                            placeholder="---Select Vehicle Type---"
                            options={VehicleHelper.vehicleTypesOptions}
                            value={VehicleHelper.vehicleTypes[vehicleReducer?.vehicleType] || vehicleReducer?.vehicleType}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value, field: e.target.name })}
                            optionListStyle={{ width: "100%" ,padding: 3 ,opacity: "1.6"}}

                            required
                        />
                        <EuiTextField className="p-1"
                            fullwidth
                            required
                            readOnly={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            style={{ padding: 3 }}
                            id="vehicleChassisNumberId"
                            inputClassName={"validation " + (VehicleHelper.isValidChassisNumber() ? " valid " : "")}
                            name="chassisNumber"
                            label="Chassis Number *"
                            pattern="[A-Za-z0-9]+"
                            validationMessage={VehicleHelper.isValidChassisNumber() ? "Valid Chassis Number" : "Only numbers and letters can be used and have to be unique."}
                            value={vehicleReducer?.chassisNumber || ""}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value?.replaceAll(/\s/g, '')?.toUpperCase(), field: e.target.name })}
                        />
                        <EuiTextField className="p-1"
                            fullwidth
                            required
                            readOnly={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            style={{ padding: 3 }}
                            id="registrationPlateNoId"
                            name="registrationPlateNo"
                            label="Registration Plate No *"
                            value={vehicleReducer?.registrationPlateNo || ""}
                            onChange={(e) => { dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value?.replaceAll(/\s/g, '')?.toUpperCase(), field: e.target.name });  }}
                        />
                        <EuiTextField className="p-1"
                            fullwidth
                            required
                            readOnly={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            style={{ padding: 3 }}
                            id="vehicleManufacturerNameId"
                            name="vehicleManufacturer"
                            label="Manufacturer *"
                            value={vehicleReducer?.vehicleManufacturer || ""}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value, field: e.target.name })}
                        />
                       <EuiTextField className="p-1"
                            fullwidth
                            required
                            readOnly
                            style={{ padding: 3 ,color: isVehicleCompliant==="Compliant" ?"green":"red"}}
                            id="IsVehicleCompliant"
                            name="IsVehicleCompliant"
                            label="Is Vehicle Compliant"
                            value={isVehicleCompliant || vehicleReducer?.isVehicleCompliant } 
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value, field: e.target.name })}
                        />
                        <EuiTextField className="p-1"
                            fullwidth
                            required
                            readOnly={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            style={{ padding: 3 }}
                            id="vehicleModelNameId"
                            name="vehicleModelName"
                            label="Model Name *"
                            value={vehicleReducer?.vehicleModelName || ""}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value, field: e.target.name })}
                        />
                        <EuiRow>
                            <div className="column sm-4 md-4 lg-4 xl-4 filled">
                                <EuiNumberField
                                required
                                    id="vehicleModelYearId"
                                    className="ml-1"
                                    disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                    name="vehicleModelYear"
                                    min={1950}
                                    max={new Date().getFullYear() + 1}
                                    label="Model Year *"
                                    style={{ width: "70%" }}
                                    value={vehicleReducer?.vehicleModelYear}
                                    onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: !isEmpty(e.target.value)&& parseInt(e.target.value), field: e.target.name })}
                                />
                            </div>
                            <div className="column sm-8 md-8 lg-8 xl-8 filled">
                                <EuiNumberField
                                required
                                    id="kmReadingId"
                                    className="mr-1"
                                    disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                    name="kmReading"
                                    label="Current Vehicle Mileage *"
                                    style={{ width: "100%", }}
                                    value={vehicleReducer?.kmReading||0}
                                    onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: !isEmpty(e.target.value)&&parseInt(e.target.value), field: e.target.name })}
                                />
                            </div>
                        </EuiRow>
                        <EuiRow>
                            <div className="column sm-6 md-6 lg-6 xl-6 filled">
                                <EuiNumberField name="lastServiceKmReading"
                                required
                                    id="lastServiceKmReadingId"
                                    className="ml-1 mt-1"
                                    disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                    label="KM Reading of Last Service *"
                                    value={vehicleReducer?.lastServiceKmReading}
                                    style={{ width: "100%" }}
                                    onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload:  !isEmpty(e.target.value)&&parseInt(e.target.value), field: e.target.name })}
                                />
                            </div>
                            <div className="column sm-6 md-6 lg-6 xl-6 filled">
                                <EuiDatepicker
                                required
                                    id="lastServiceDatetimeId"
                                    className="mt-1 ml-1 mr-1"
                                    disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                    label="Last Service DateTime *"
                                    placeholder="YYYY-MM-DD"
                                    name="lastServiceDatetime"
                                    value={vehicleReducer?.lastServiceDatetime}
                                    onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value, field: e.target.name })}
                                />
                            </div>
                      
                            <div className="column sm-2 md-2 lg-6 xl-6 filled">
                                <EuiNumberField
                                required
                                   name="mileage"
                                    id="mileageId"
                                    className="ml-1 mt-1"
                                    min={vehicleReducer?.kmReading}
                                    label="Next Service Mileage *"
                                    value={vehicleReducer?.mileage}
                                    style={{ width: "100%" }}
                                    inputClassName={"validation " + (parseInt(vehicleReducer?.mileage)>=  parseInt(vehicleReducer?.kmReading) ? " valid " : "")}
                                    onChange={ (e) =>  dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: parseInt(e.target.value), field: e.target.name })}
                                    validationMessage={ parseInt(vehicleReducer?.mileage)>=  parseInt(vehicleReducer?.kmReading)? "Valid next service mileage" :  ''}

                                />
                            </div> 
                        </EuiRow>
                    </div>

                    <div className="column sm-12 md-8 lg-8 xl-8 filled">
                        <EuiCheckbox name="fittedWithAbs"
                            id="fittedWithAbsId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with ABS braking system"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer?.fittedWithAbs === 1 ? true : false}
                            onChange={(e) => { dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name }); }}
                        />
                        <EuiCheckbox name="fittedWithSeatBelt"
                            id="fittedWithSeatBeltId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with Seat belts and headrests applicable to all seats"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer?.fittedWithSeatBelt === 1 ? true : false}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                        />
                        <EuiCheckbox name="fittedWithHeadRests"
                            id="fittedWithHeadRestsId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with headrests "
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer?.fittedWithHeadRests === 1 ? true : false}
                            onChange={(e) => { dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name }); }}
                        />
                        <EuiCheckbox name="fittedWithRolloverProtection"
                            id="fittedWithRolloverProtectionId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with roll-over protection / Bars (pickups or similar)"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer?.fittedWithRolloverProtection === 1 ? true : false}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                        />
                        <EuiCheckbox name="fittedWithApprTyres"
                            id="fittedWithApprTyresId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with Appropriate Tyres / Replaced considering milage usage and life time"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer?.fittedWithApprTyres === 1 ? true : false}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                        />
                        <EuiCheckbox name="fittedWithHeadsFreeKit"
                            id="fittedWithHeadsFreeKitId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with Hands-free Kits (where safe and legally to do so)"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer?.fittedWithHeadsFreeKit === 1 ? true : false}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                        />
                           <EuiCheckbox name="roadworthyAndInsured"
                            id="roadworthyAndInsuredId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Roadworthy And Insured"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer?.roadworthyAndInsured === 1 ? true : false}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                        />
                        <div className="tile-title">Emergency Requirements</div>
                        <div className="content tile-bordered mt-2" style={{ paddingLeft: 2 }}>
                            <EuiCheckbox name="emergencyEqptTriAngles"
                                id="emergencyEqptTriAnglesId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Minimum emergency equipment 2x emergency tri-angles"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer?.emergencyEqptTriAngles === 1 }
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="emergencyEqpt25Fe"
                                id="emergencyEqpt25FeId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Minimum emergency equipment  1x 2.5 Kg FE"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer?.emergencyEqpt25Fe === 1}
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="emergencyEqptFaKit"
                                id="emergencyEqptFaKitId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Minimum emergency equipment  1 x FA Kit"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer?.emergencyEqptFaKit === 1 }
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="emergencyEqptHighReflectorVest"
                                id="emergencyEqptHighReflectorVestId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Minimum emergency equipment  High-visible reflector vest"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer?.emergencyEqptHighReflectorVest === 1 }
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                        </div>
                        <div className="tile-title">GPS/Telematics Requirements</div>
                        <div className="content tile-bordered mt-2" style={{ paddingLeft: 2 }}>
                            <EuiCheckbox name="gpsInstalled"
                                id="gpsInstalledId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Installed in vehicle"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer?.gpsInstalled === 1}
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="gpsSystemAccessGranted"
                                id="gpsSystemAccessGrantedId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="System Access Granted"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer?.gpsSystemAccessGranted === 1 }
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="gpsLiveTracking"
                                id="gpsLiveTrackingId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Active/live tracking Reports"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer?.gpsLiveTracking === 1 }
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="driverBehaviourMonitor"
                                id="driverBehaviourMonitoredId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Driver Behaviour Monitor"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer?.driverBehaviourMonitor === 1 }
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                       
                        </div>

                        {/* <div className="tile-title">ASP Companies</div>
                        <div className="content tile-bordered mt-2" style={{ paddingLeft: 2 }}>
                        
					   
					   <FormField className="w-10">
                        <label htmlFor="countries" style={{ color: "gray", fontSize: 14 }}>Countries</label>
                        <AutoCompleteSelect
                            required={false}
                            id="countryId"
                            options={countries}
                            name="countries"
                            onChange={e => handleChangeCountry(e)}
                            value={country}
                            placeholder="select country"
                            isLoading={isLoading}
                        />
                    </FormField>
                     
                    <FormField className="w-10">
                         <label  htmlFor="aspCompanies" style={{ color: "gray", fontSize: 14 }}>asp Companies</label>
                         <AutoCompleteSelect
                             required={false}
                             id="aspConpanyID"
                             options={aspCompanies}
                             name="aspCompanId"
                             onChange={e => handleChangeAspCompany(e)}
                             value={aspCompany }
                             placeholder="select aspCompany"
                             isLoading={isLoading}
                         />
                     </FormField> */}
                        {/* </div> */}
                     
                        

                    </div>
     
                    <div className="column sm-12 md-12 lg-12 xl-12 filled pl-1">
                        {getUserModificationsSection()}
                    </div>

                </EuiRow>
            </EuiTile>
          
            
        
        </>
    );

});

export default VehicleContent;